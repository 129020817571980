import React from "react";
import { Box, Typography, Grid, Card, CardMedia } from "@mui/material";
import Tech from "../../images/D6.png";
import Dev from "../../images/D7.png";
import AI from "../../images/D8.png";
import Robotics from "../../images/D5.png";

const areas = [
  {
    title: "Technology",
    description:
      "Exploring cutting-edge technological innovations and solutions",
    image: Tech,
  },
  {
    title: "Development",
    description: "Building robust and scalable software solutions",
    image: Dev,
  },
  {
    title: "Artificial Intelligence",
    description: "Advancing AI capabilities and applications",
    image: AI,
  },
  {
    title: "Robotics",
    description: "Innovating in robotics and automation",
    image: Robotics,
  },
];

export default function FocusAreas() {
  return (
    <Box sx={{width: "100%", mx: "auto" , background: '#fff' , py:2 }}> 
    <Box sx={{ py: 2, px: 1, width: "90%", mx: "auto", background: "#fff" }}>
      <Typography variant="h4" fontWeight="bold" textAlign="center" mb={6}>
        Focus Areas
      </Typography>
      <Grid container spacing={2}>
        {areas.map((area, index) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            key={index}
            sx={{
              width: "150px",
              height: "450px",
              mt: 2
            }}
          >
            <Card
              sx={{
                position: "relative",
                overflow: "hidden",
                borderRadius: 3,
                // aspectRatio: "3/4",
                width: "100%",
                height: "450px",
                cursor: "pointer",
                "&:hover .overlay": { opacity: 1 },
                "&:hover .title": { opacity: 1, transform: "translateY(0)" },
              }}
            >
              <CardMedia
                component="img"
                src={area.image}
                alt={area.title}
                sx={{ width: "100%", height: "100%" }}
              />
              <Box
                className="overlay"
                sx={{
                  position: "absolute",
                  inset: 0,
                  bgcolor: "rgba(0,0,0,0.7)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  opacity: 0,
                  transition: "opacity 0.3s ease-in-out",
                }}
              >
                <Typography
                  variant="h3"
                  className="title"
                  sx={{
                    color: "white",
                    fontWeight: "bold",
                    textAlign: "center",
                    textTransform: "uppercase",
                    transform: "translateY(20px)",
                    transition:
                      "transform 0.3s ease-in-out, opacity 0.3s ease-in-out",
                    opacity: 0,
                    writingMode: "vertical-lr",
                    textOrientation: "mixed",
                  }}
                >
                  {area.title}
                </Typography>
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
    </Box>
  );
}
