import React, { useEffect, useState } from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  CircularProgress,
  Box,
  Button,
} from "@mui/material";
import axios from "axios";
import { ROLE } from "../../api/role";

const OpenPositions = () => {
  const [positions, setPositions] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchPositions();
  }, []);

  const fetchPositions = async () => {
    try {
      const response = await axios.get(ROLE);
      setPositions(response.data || []);
    } catch (error) {
      console.error("Error fetching positions:", error);
      setPositions([]);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box p={3} pt={20} sx={{ background: "#fff" }}>
      <Typography variant="h4" mb={3} textAlign="center">
        Open Positions
      </Typography>

      {loading ? (
        <Box display="flex" justifyContent="center" mt={5}>
          <CircularProgress />
        </Box>
      ) : positions.length === 0 ? (
        <Typography variant="h6" textAlign="center" color="gray">
          No Current Opening as of now
        </Typography>
      ) : (
        <Grid container spacing={2}>
          {positions.map((position, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card
                sx={{
                  borderLeft: "4px solid #0086FF",
                  borderRadius: "8px",
                  boxShadow: "0 2px 10px rgba(0,0,0,0.1)",
                  height: "100%",
                }}
              >
                <CardContent>
                  <Typography variant="h6" fontWeight="bold">
                    {position.role}
                  </Typography>
                  <Typography variant="body2" mt={1}>
                    Mode: <strong>{position.mode}</strong>
                  </Typography>
                  <Typography variant="body2" mt={1}>
                    Type: <strong>{position.type}</strong>
                  </Typography>
                  <Typography variant="body2" mt={1}>
                    Location: <strong>{position.location}</strong>
                  </Typography>
                  {position.link && (
                    <Box mt={2}>
                      <Button
                        variant="outlined"
                        size="small"
                        href={position.link}
                        target="_blank"
                        sx={{ textTransform: "none" }}
                      >
                        Apply Now
                      </Button>
                    </Box>
                  )}
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  );
};

export default OpenPositions;
