import ConnectImage from "../../images/Main Page.png";
import ContactImage from "../../images/F4.png";
import RdPartnerImage from "../../images/F8.png";
import RdContactImage from "../../images/F6.png";
import RdReportAccessImage from "../../images/F8.png";
import JoinCommunityImage from "../../images/Main Page.png";
import MpImage from "../../images/Partner.png";
import MentorImage from "../../images/member.png";

const formFields = {
  "Main Partner US": {
    heading: "Partner With Us",
    description: "Fill out the form below to submit your partnership request",
    image: MpImage,
    fields: [
      { name: "firstName", label: "First Name", required: true },
      { name: "lastName", label: "Last Name", required: true },
      { name: "organization", label: "Organization", required: false },
      { name: "designation", label: "Designation", required: false },
      { name: "phoneNumber", label: "Contact Number", required: true },
      { name: "email", label: "Email", required: true },
      { name: "linkedInId", label: "LinkedIn ID", required: false },
      {
        name: "markoknowPartner",
        label: "Markoknow Partner",
        type: "dropdown",
        required: false,
        options: [
          { value: "Markoknow Mentor", label: "Markoknow Mentor" },
          {
            value: "Markoknow Student Partner",
            label: "Markoknow Student Partner",
          },
          {
            value: "Markoknow Workshop Partner",
            label: "Markoknow Workshop Partner",
          },
          {
            value: "Markoknow Service Partner",
            label: "Markoknow Service Partner",
          },
          {
            value: "Markoknow Investor Partner",
            label: "Markoknow Investor Partner",
          },
        ],
      },
    ],
  },

  "Main Apply as Mentor": {
    heading: "Apply as Member",
    description: "Apply to become a mentor with us",
    image: MentorImage,
    fields: [
      { name: "firstName", label: "First Name", required: true },
      { name: "lastName", label: "Last Name", required: true },
      { name: "email", label: "Email", required: true },
      { name: "phoneNumber", label: "Contact Number", required: true },
      {
        name: "designation",
        label: "Designation",
        type: "dropdown",
        required: true,
        options: [
          { value: "Student", label: "Student" },
          { value: "Professional", label: "Professional" },
          { value: "Other", label: "Other" },
        ],
      },
    ],
  },

  "Main Page - Connect": {
    heading: "Connect With Us",
    description: "Connect with us for general inquiries",
    image: ConnectImage,
    fields: [
      { name: "firstName", label: "First Name", required: true },
      { name: "lastName", label: "Last Name", required: true },
      { name: "organization", label: "Organization", required: false },
      { name: "email", label: "Email", required: true },
      { name: "phoneNumber", label: "Phone Number", required: true },
      { name: "purpose", label: "Purpose", required: true, type: "textarea" },
    ],
  },

  "Contact - Main Page": {
    heading: "Contact Us",
    description: "Contact us for more information",
    image: ContactImage,
    fields: [
      { name: "firstName", label: "First Name", required: true },
      { name: "lastName", label: "Last Name", required: true },
      { name: "companyName", label: "Company Name", required: true },
      { name: "email", label: "Company Email", required: true },
      { name: "phoneNumber", label: "Phone Number", required: true },
      { name: "query", label: "Your query", required: true, type: "textarea" },
    ],
  },

  "R&D Partner Us": {
    heading: "R&D Partnership",
    description:
      "Fill out the form below to submit your R&D partnership request",
    image: RdPartnerImage,
    fields: [
      { name: "firstName", label: "First Name", required: true },
      { name: "lastName", label: "Last Name", required: true },
      { name: "organization", label: "Organization", required: false },
      { name: "designation", label: "Designation", required: false },
      { name: "phoneNumber", label: "Contact Number", required: true },
      { name: "email", label: "Email", required: true },
      { name: "linkedInId", label: "LinkedIn ID", required: false },
      {
        name: "markoknowPartner",
        label: "Markoknow Partner",
        type: "dropdown",
        required: false,
        options: [
          { value: "Markoknow Mentor", label: "Markoknow Mentor" },
          {
            value: "Markoknow Student Partner",
            label: "Markoknow Student Partner",
          },
          {
            value: "Markoknow Workshop Partner",
            label: "Markoknow Workshop Partner",
          },
          {
            value: "Markoknow Service Partner",
            label: "Markoknow Service Partner",
          },
          {
            value: "Markoknow Investor Partner",
            label: "Markoknow Investor Partner",
          },
        ],
      },
    ],
  },

  "R&D Contact Us": {
    heading: "R&D Contact",
    description: "Connect with our R&D team",
    image: RdContactImage,
    fields: [
      { name: "firstName", label: "First Name", required: true },
      { name: "lastName", label: "Last Name", required: true },
      { name: "organization", label: "Organization", required: false },
      { name: "email", label: "Email", required: true },
      { name: "phoneNumber", label: "Phone Number", required: true },
      { name: "purpose", label: "Purpose", required: true, type: "textarea" },
    ],
  },

  "R&D Report Access": {
    heading: "R&D Report Access",
    description: "Request access to our R&D reports",
    image: RdReportAccessImage,
    fields: [
      { name: "firstName", label: "First Name", required: true },
      { name: "lastName", label: "Last Name", required: true },
      { name: "companyName", label: "Company Name", required: true },
      { name: "email", label: "Company Email", required: true },
      { name: "phoneNumber", label: "Phone Number", required: true },
      {
        name: "query",
        label: "Your request details",
        required: true,
        type: "textarea",
      },
    ],
  },

  "Join Community": {
    heading: "Join Our Community",
    description: "Become part of our growing community",
    image: JoinCommunityImage,
    fields: [
      { name: "firstName", label: "First Name", required: true },
      { name: "lastName", label: "Last Name", required: true },
      { name: "organization", label: "Organization", required: false },
      { name: "email", label: "Email", required: true },
      { name: "phoneNumber", label: "Phone Number", required: true },
      { name: "purpose", label: "Purpose", required: true, type: "textarea" },
    ],
  },
};

export default formFields;
