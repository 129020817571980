import React from 'react';
import freecontent from '../../../images/I-1.png';
import practical from '../../../images/I-2.png';
import lpa from '../../../images/I-3.png';
import { Box } from '@mui/material';
import "../../../stylesheets/imagesetup.css";


const ImageSetup = () => {
  return (
    <Box className="image-container" style={{width: '100%' , display:'flex' , alignItems: 'center' , justifyContent:' center'}}>
      <Box className="image-box" >
         <img src={practical} alt="Practical" className="rounded-image-2 animation-2" />
         <img src={lpa} alt="LPA" className="rounded-image animation-1" />  
      </Box>
      <Box className="image-box-2">
      <img src={freecontent} alt="Free Content" className="rounded-image-1 animation-1" />
      </Box>
    </Box>
  )
}

export default ImageSetup