import React from "react";
import { Link } from "react-router-dom";
import { Box, Typography, Button, Grid } from "@mui/material";
import Belief from '../../../images/Belief.jpg';
import Person from '../../../images/People.jpg';
import Team from '../../../images/Team.jpg';

export default function LifeSection() {
  return (
    <Box sx={{ maxWidth: "1280px", mx: "auto", px: 2, py: 8 }}>
      <Typography variant="h1" sx={{ fontSize: { xs: "32px", md: "40px" }, fontWeight: "bold", mb: 6 }}>
        Life at <span style={{ color: "#2563eb" }}>Markoknow</span>
      </Typography>

      <Grid container spacing={4}>
        {/* Employability Block */}
        <Grid item xs={12} md={4}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <Box
              sx={{
                position: "relative",
                aspectRatio: "4/3",
                borderRadius: "8px",
                overflow: "hidden",
                bgcolor: "yellow.100",
              }}
            >
              <img
                src={Belief}
                alt="Paper cutout figures representing community"
                width="100%"
                height="100%"
                style={{ objectFit: "cover" }}
              />
            </Box>
            <Typography variant="h2" sx={{ fontSize: "20px", fontWeight: "bold", lineHeight: "1.3" }}>
              Our Belief for Bringing Employability at Deep Root Levels of Society
            </Typography>
            <Button component={Link} to="#" sx={{ color: "#2563eb", fontWeight: "bold", "&:hover": { color: "#1d4ed8" } }}>
              Read More
            </Button>
          </Box>
        </Grid>

        {/* People Factor Block */}
        <Grid item xs={12} md={4}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <Box
              sx={{
                position: "relative",
                aspectRatio: "4/3",
                borderRadius: "8px",
                overflow: "hidden",
              }}
            >
              <img
                src={Person}
                alt="Group of people collaborating outdoors"
                width="100%"
                height="100%"
                style={{ objectFit: "cover" }}
              />
            </Box>
            <Typography variant="h2" sx={{ fontSize: "20px", fontWeight: "bold", lineHeight: "1.3" }}>
              We believe in People Factor as the Most Important Edge to Growth
            </Typography>
            <Button component={Link} to="#" sx={{ color: "#2563eb", fontWeight: "bold", "&:hover": { color: "#1d4ed8" } }}>
              Know More
            </Button>
          </Box>
        </Grid>

        {/* Join Us Block */}
        <Grid item xs={12} md={4}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <Box
              sx={{
                position: "relative",
                aspectRatio: "4/3",
                borderRadius: "8px",
                overflow: "hidden",
              }}
            >
              <img
                src={Team}
                alt="Professional meeting environment"
                width="100%"
                height="100%"
                style={{ objectFit: "cover" }}
              />
            </Box>
            <Typography variant="h2" sx={{ fontSize: "20px", fontWeight: "bold", lineHeight: "1.3" }}>
              Come Join Us as a Team in Our Processes or Volunteer for Our Social Cause
            </Typography>
            <Button component={Link} to="#" sx={{ color: "#2563eb", fontWeight: "bold", "&:hover": { color: "#1d4ed8" } }}>
              Join Us
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
