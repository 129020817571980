import React, { useState } from "react";
import { Box, Button, TextField, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
// import { withStyles } from "@material-ui/core/styles";
import axios from "axios";
import Test from "./Test";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
const DOMAIN = process.env.REACT_APP_DOMAIN;
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const useStyles = makeStyles({
  wrapper: {
    display: "flex",
    background: "linear-gradient(to top left, #6A58C6, #a8f5ff) !important",
    flexDirection: "column",
    alignItems: "center",
    //justifyContent:'center',
    // border: "1px solid red",

    paddingTop: "0.8% important",
    paddingLeft: "5%",
    paddingRight: "5%",
    paddingBottom: "2rem",
    height: "85vh",
    overflow: "hidden !important",
    "@media only screen and (max-width: 1280px)": {
      height: "100%",
      paddingBottom: "20%",
    },
    "@media only screen and (max-width: 750px)": {
      display: "flex",
      background: "linear-gradient(to top left, #6A58C6, #a8f5ff) !important",
      flexDirection: "column",
      alignItems: "center",
      //justifyContent:'center',
    },
  },

  headingbox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    //backgroundColor:'yellow',
    width: "100%",
    marginTop: "0rem",
    marginBottom: "1.5rem",
    "@media only screen and (max-width: 750px)": {
      marginTop: "0.3rem",
    },
  },
  heading1: {
    fontSize: "48px",
    // color: "#251E4F",
    color: "#251E4F",
    "@media only screen and (max-width: 750px)": {
      fontSize: "36px",
      color: "white",
      marginBottom: "5px",
    },
  },
  heading2: {
    color: "#251E4F",
  },
  container: {
    display: "flex",
    width: "70%",
    color: "white",
    padding: "1rem",
    //backgroundColor:'yellow',
    flexDirection: "column",
    marginTop: "40px",
    borderRadius: "10px",
    background: "linear-gradient(to top left, #ffffffff,#ffffff33)",
    backdropFilter: "blur(15px)",
    boxShadow: "0 8px 32px 0 rgba(31, 38, 135, 0.37)",
    "@media only screen and (max-width: 750px)": {
      display: "flex",
      width: "100%",
      //backgroundColor:'yellow',
      flexDirection: "column",
      color: "white",
    },
  },
  ratingsBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "black",
    width: "100%",
    marginBottom: "1rem",
    "@media only screen and (max-width: 750px)": {
      display: "flex",
      alignItems: "center",
      alignItems: "center",
      justifyContent: "space-between",
      width: "100%",
      flexDirection: "column",

      marginBottom: "2px",
    },
  },
  leftbox: {
    flex: "5",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    //backgroundColor:'blue',
    "@media only screen and (max-width: 750px)": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginBottom: "20px",
      //backgroundColor:'blue',
    },
  },
  rightbox: {
    flex: "7",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "white",
    "@media only screen and (max-width: 750px)": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: "white",
      //backgroundColor:'red'
    },
  },
  leftboxtext: {
    fontSize: "28px",
    color: "#251E4F",
  },
  ratingicons: {
    //backgroundColor:'blue',
    icon: {
      height: 64,
      width: 64,
    },
  },
  buttonBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    //backgroundColor:'red',
    width: "100%",
    marginBottom: "1.2rem",
    "@media only screen and (max-width: 750px)": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      //backgroundColor:'yellow',
      width: "100%",
      flexDirection: "column",
      marginTop: "10px",
    },
  },
  buttonsets: {
    display: "flex",
    flexDirection: "column",
    //backgroundColor:'blue',
    width: "100%",
  },
  buttonset: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  button: {
    height: "40px",
    width: "20%",
    backgroundColor: "#6A58C6",
    borderRadius: "100px",
    color: "white",
    margin: "5px",
    "&:focus": { backgroundColor: "white", color: "black" },
    "@media only screen and (max-width: 750px)": {
      height: "45px",
      width: "32%",
      fontSize: "12px",
    },
  },
  textBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "white",
    //backgroundColor:'blue',
    width: "100%",

    marginBottom: "1.2rem",
    "@media only screen and (max-width: 750px)": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      //backgroundColor:'yellow',
      width: "100%",
      margin: "auto",
      flexDirection: "column",
      marginTop: "10px",
    },
  },
  text: {
    height: "150px",
    width: "400px",
    backgroundColor: "#6A58C6",
    display: "flex",
    fontFamily: "sans-serif",
    fontSize: "20px",
    borderRadius: "10px",
    padding: "10px",
    fontWeight: "500",
    border: "none",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "wrap",
    color: "white",
    whiteSpace: "initial",
    "@media only screen and (max-width: 900px)": {
      width: "300px",
    },
    "@media only screen and (max-width: 750px)": {
      width: "75vw",
    },
  },
  submitbtnBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer !important",
    width: "100%",
    marginTop: "1rem",
  },
  submitbtn: {
    backgroundColor: "#251E4F",
    borderRadius: "20px",
    color: "white",
    width: "30%",
    padding: "1rem",
    fontWeight: "600",
    fontSize: "1rem",
    border: "none",
    height: "40px",
    marginTop: "0rem",
    "&:hover": {
      backgroundColor: "#6A38B9",
    },
  },
});

const Feedback = () => {
  const classes = useStyles();
  const [rating, setRating] = useState(0);
  const [liked, setLiked] = useState("not given");
  const [state, setState] = useState(false);
  const [feedback, setFeedback] = useState("");
  const [open, setOpen] = useState(false);
  // const largeStyles = {
  //     iconButton: {
  //       width: 90,
  //       height: 90,
  //       padding: 23,
  //       //backgroundColor:'blue',
  //         "@media only screen and (max-width: 420px)":{
  //             width: 60,
  //             height: 60,
  //             padding: 15,
  //             //backgroundColor:'blue',
  //         },
  //         // "@media only screen and (max-width: 1024px)":{
  //         //     width: 90,
  //         //     height: 90,
  //         //     padding: 23,
  //         //     //backgroundColor:'blue',
  //         // }
  //     },
  //     icon: {
  //       width: 45,
  //       height: 45,
  //       //backgroundColor:'blue',
  //         "@media only screen and (max-width: 420px)":{
  //             width: 30,
  //             height: 30,
  //             //backgroundColor:'blue',
  //         },
  //         // "@media only screen and (max-width: 1024px)":{
  //         //     width: 45,
  //         //     height: 45,
  //         //     //backgroundColor:'blue',
  //         // }
  //     }
  // };
  // const MyRating = ({ classes }) => (
  //     <Rating
  //       defaultValue={5}
  //       max={5}
  //       onChange={i => setRating(i)}
  //       classes={classes}
  //     />
  // );
  // const LargeRating = withStyles(largeStyles)(MyRating);
  const MakeAllFalse = () => {
    setRating(0);
    setFeedback("");
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const HandleSubmit = () => {
    setOpen(true);

    // axios
    //   .post(
    //     `${DOMAIN}/api/email/feedback`,
    //     { rating, liked, feedback },
    //     console.log(rating, liked, feedback),
    //     MakeAllFalse(),
    //     {
    //       headers: {
    //         Authorization: `Bearer ${window.localStorage.getItem("token")}`,
    //       },
    //     }
    //   )
    //   .then((res) => {
    //     console.log(res);
    //     setCounter(!counter);
    //     console.log(counter);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
    axios
      .post(
        `${DOMAIN}/api/email/feedback`,
        { rating, liked, feedback },
        {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        console.log(res);
        MakeAllFalse();
        setOpen(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <Box className={classes.wrapper}>
      <Box className={classes.container}>
        <Box className={classes.headingbox}>
          <Typography className={classes.heading1}>Feedback</Typography>
          <Typography className={classes.heading2}>
            Tell us Your Experience with Markoknow.
          </Typography>
        </Box>
        <Box className={classes.ratingsBox}>
          <Box className={classes.leftbox}>
            <Typography className={classes.leftboxtext}>Rating</Typography>
          </Box>
          <Box className={classes.rightbox}>
            <Test rating={rating} setRating={setRating} />
          </Box>
        </Box>
        <Box className={classes.buttonBox}>
          <Box className={classes.leftbox}>
            <Typography className={classes.leftboxtext}>
              What you liked the most?
            </Typography>
          </Box>
          <Box className={classes.rightbox}>
            <Box className={classes.buttonsets}>
              <Box className={classes.buttonset}>
                <Button
                  onClick={() => setLiked("Website")}
                  className={classes.button}
                >
                  Website
                </Button>
                <Button
                  onClick={() => setLiked("Events")}
                  className={classes.button}
                >
                  Events
                </Button>
                <Button
                  onClick={() => setLiked("Learning")}
                  className={classes.button}
                >
                  Learning
                </Button>
                <Button
                  onClick={() => setLiked("Community")}
                  className={classes.button}
                >
                  Community
                </Button>
              </Box>
              <Box className={classes.buttonset}>
                <Button
                  onClick={() => setLiked("Mentors")}
                  className={classes.button}
                >
                  Mentors
                </Button>
                <Button
                  onClick={() => setLiked("NetWorking")}
                  className={classes.button}
                >
                  Networking
                </Button>
                <Button
                  onClick={() => setLiked("Others")}
                  className={classes.button}
                >
                  Others
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className={classes.textBox}>
          <Box className={classes.leftbox}>
            <Typography className={classes.leftboxtext}>Feedback</Typography>
          </Box>
          <Box className={classes.rightbox}>
            <textarea
              value={feedback}
              onChange={(e) => setFeedback(e.target.value)}
              className={classes.text}
              multiline
              rows={4}
              variant="outlined"
            />
          </Box>
        </Box>
        <Box className={classes.submitbtnBox}>
          <Button
            onClick={() => {
              HandleSubmit();
            }}
            className={classes.submitbtn}
          >
            Submit
          </Button>
        </Box>
      </Box>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          Thank you for your feedback!
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Feedback;
