import React from "react";
import { Typography, Container, Grid } from "@mui/material";

export default function MarkoknowPage() {
  return (
    <div style={{ minHeight: "100vh" }}>
      {/* Hero Section */}
      <Container sx={{ py: { xs: 6, md: 10 } }}>
        <Grid container spacing={6}>
          {/* Left Column */}
          <Grid item xs={12} md={6}>
            <Typography variant="h3" fontWeight="bold" gutterBottom>
              "We Believe in Driving <span style={{ color: "#1976D2" }}>Impact - Growth - Change</span> to help our
              People lead with Latest Trends"
            </Typography>
            <Typography variant="h6" color="text.secondary">
              By truly empowering our People as - Business Leaders & Professionals, we aim to keep them ahead in the
              trend, Adapt Faster and Better, Lead the Growth and Create Lasting Impact.
            </Typography>
          </Grid>

          {/* Right Column - Stats */}
          <Grid item xs={12} md={6}>
            <Typography variant="h4" fontWeight="bold" gutterBottom>
              About Markoknow
            </Typography>
            <Grid container spacing={4}>
              {[
                { value: "50K+", text: "People Thriving with us as a Community, navigating growth and leading Impact" },
                { value: "46+", text: "Cities in India Covered with our Community Members" },
                {
                  value: "5+",
                  text: "Innovative Products Launched within our umbrella on segments of Education, Business, and Latest Technology",
                },
                { value: "3+", text: "Ongoing Innovation Projects to Bring Innovation and Technology as the core to us" },
              ].map((stat, index) => (
                <Grid item xs={12} sm={6} key={index}>
                  <Typography variant="h3" fontWeight="bold" color="primary">
                    {stat.value}
                  </Typography>
                  <Typography variant="body1" fontWeight="medium">
                    {stat.text}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
