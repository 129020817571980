import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  IconButton,
  TextField,
  Button,
  Typography,
  useMediaQuery,
  Box,
  CircularProgress,
  MenuItem,
  Card,
  CardContent,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import formFields from "./formFields";
import { POST_FORM } from "../../api/form";

const Form = ({ open = true, onClose, category, type = "popup" }) => {
  const isMobile = useMediaQuery("(max-width:768px)");

  const fields = formFields[category]?.fields;
  const image = formFields[category]?.image;
  const heading = formFields[category]?.heading;
  const [formData, setFormData] = useState(
    fields?.reduce((acc, field) => ({ ...acc, [field.name]: "" }), {})
  );
  const [otherFields, setOtherFields] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleOtherChange = (fieldName, value) => {
    setOtherFields({ ...otherFields, [fieldName]: value });
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const payload = {
        category,
        ...formData,
        ...Object.keys(otherFields).reduce((acc, key) => {
          if (formData[key] === "Other") acc[key] = otherFields[key];
          return acc;
        }, {}),
      };
      await fetch(POST_FORM, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      });
      setSubmitted(true);
    } catch (err) {
      alert("Failed to submit. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const FormContent = (
    <Box
      sx={{
        display: "flex",
        flexDirection: isMobile ? "column" : "row",
        padding: "24px",
        position: "relative",
        minHeight: "300px",
      }}
    >
      {type === "popup" && (
        <IconButton
          onClick={onClose}
          sx={{ position: "absolute", top: 12, right: 12 }}
        >
          <CloseIcon />
        </IconButton>
      )}

      {!isMobile && !submitted && image && (
        <img
          src={image}
          alt="form"
          style={{
            width: "50%",
            borderRadius: "12px",
            objectFit: "cover",
            marginRight: "20px",
          }}
        />
      )}

      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: isMobile ? "center" : "flex-start",
          justifyContent: "center",
        }}
      >
        {!submitted ? (
          <>
            <Typography
              variant="h5"
              fontWeight="bold"
              sx={{ mb: 2, textAlign: isMobile ? "center" : "left" }}
            >
              {heading}
            </Typography>

            {fields.map((field) => (
              <Box key={field.name} sx={{ width: "100%", mb: 2 }}>
                {field.type === "dropdown" ? (
                  <>
                    <TextField
                      select
                      name={field.name}
                      label={field.label}
                      required={field.required}
                      fullWidth
                      value={formData[field.name]}
                      onChange={handleChange}
                    >
                      {field.options?.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                      <MenuItem value="Other">Other</MenuItem>
                    </TextField>
                    {formData[field.name] === "Other" && (
                      <TextField
                        label={`Enter ${field.label}`}
                        value={otherFields[field.name] || ""}
                        onChange={(e) =>
                          handleOtherChange(field.name, e.target.value)
                        }
                        fullWidth
                        sx={{ mt: 1 }}
                      />
                    )}
                  </>
                ) : field.type === "textarea" ? (
                  <TextField
                    name={field.name}
                    label={field.label}
                    required={field.required}
                    value={formData[field.name]}
                    onChange={handleChange}
                    fullWidth
                    multiline
                    rows={4}
                  />
                ) : (
                  <TextField
                    name={field.name}
                    label={field.label}
                    required={field.required}
                    value={formData[field.name]}
                    onChange={handleChange}
                    fullWidth
                  />
                )}
              </Box>
            ))}

            <Button
              fullWidth
              variant="outlined"
              onClick={handleSubmit}
              disabled={loading}
              sx={{
                borderRadius: "30px",
                padding: "10px",
                borderWidth: "2px",
                background:
                  "linear-gradient(to right, #00C9FF, #92FE9D, #F7971E)",
                color: "#000",
                fontWeight: 600,
                mt: 1,
              }}
            >
              {loading ? <CircularProgress size={24} /> : "Submit"}
            </Button>
          </>
        ) : (
          <Typography
            variant="h5"
            fontWeight="bold"
            sx={{ textAlign: "center", width: "100%" }}
          >
            Thank you for responding!
          </Typography>
        )}
      </Box>
    </Box>
  );

  return type === "popup" ? (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogContent>{FormContent}</DialogContent>
    </Dialog>
  ) : (
    <Card
      sx={{
        maxWidth: "900px",
        margin: "auto",
        mt: 4,
        boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
        borderRadius: "12px",
      }}
    >
      <CardContent>{FormContent}</CardContent>
    </Card>
  );
};

export default Form;
