import React, { useRef, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import {
  Container,
  Box,
  Card,
  CardContent,
  Typography,
  IconButton,
  useMediaQuery,
  useTheme,
  Button,
} from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Form from "../Form"; // adjust path if needed

const OngoingProjects = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));
  const carouselRef = useRef(null);

  const [openCommunity, setOpenCommunity] = useState(false);

  const handleOpen = () => {
    setOpenCommunity(true);
  };

  const handleClose = () => {
    setOpenCommunity(false);
  };

  const slides = [
    {
      title: "Hyper Personalization using Artificial Intelligence",
      content:
        "Hyper personalization leverages AI, machine learning, and real-time data to deliver ultra-customized experiences tailored to individual users...",
    },
    {
      title: "Smart Factories & Industry 4.0",
      content:
        "Research from PwC suggests smart manufacturing could add $1.5 trillion to the global economy by 2030, improving efficiency and reducing operational costs...",
    },
    {
      title: "Energy Efficiency Innovations & Investment in ESG",
      content:
        "Exploring new advancements in energy efficiency and sustainable investments for a better future...",
    },
  ];

  const items = slides.map((slide, index) => (
    <Card
      key={index}
      sx={{
        bgcolor: "#0a4b8f",
        color: "white",
        borderRadius: 2,
        padding: 3,
        width: 350,
        height: 350,
      }}
    >
      <CardContent>
        <Typography variant="h5" fontWeight="bold" mb={1}>
          {slide.title}
        </Typography>
        <Box sx={{ width: 40, height: 4, bgcolor: "#ffd700", mb: 1.5 }} />
        <Typography variant="body1" fontSize="0.9rem">
          {slide.content}
        </Typography>
      </CardContent>
    </Card>
  ));

  return (
    <Box sx={{ background: "#fff", padding: 3 }}>
      <Container maxWidth="lg">
        <Box
          sx={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            alignItems: "center",
            gap: 4,
            textAlign: isMobile ? "center" : "left",
          }}
        >
          <Box>
            <Typography variant={isMobile ? "h4" : "h3"} fontWeight="bold">
              Our Ongoing
            </Typography>
            <Typography variant={isMobile ? "h4" : "h3"} fontWeight="bold">
              Innovation
            </Typography>
            <Typography
              variant={isMobile ? "h4" : "h3"}
              fontWeight="bold"
              mb={2}
            >
              Projects
            </Typography>
            <Box>
              <IconButton
                onClick={() =>
                  carouselRef.current?.moveTo(
                    carouselRef.current.state.selectedItem - 1
                  )
                }
                sx={{
                  bgcolor: "#6a58c6",
                  color: "white",
                  "&:hover": { bgcolor: "#5a48b6" },
                  mx: 1,
                }}
              >
                <ArrowBackIosNewIcon />
              </IconButton>
              <IconButton
                onClick={() =>
                  carouselRef.current?.moveTo(
                    carouselRef.current.state.selectedItem + 1
                  )
                }
                sx={{
                  bgcolor: "#6a58c6",
                  color: "white",
                  "&:hover": { bgcolor: "#5a48b6" },
                  mx: 1,
                }}
              >
                <ArrowForwardIosIcon />
              </IconButton>
            </Box>
          </Box>
          <Box width={"100%"}>
            <Carousel
              ref={carouselRef}
              showThumbs={false}
              infiniteLoop
              autoPlay
              interval={3000}
              showStatus={false}
              showIndicators={false}
              centerMode={!isMobile}
              centerSlidePercentage={isMobile ? 80 : isTablet ? 50 : 33.33}
              showArrows={false}
            >
              {items}
            </Carousel>
          </Box>
        </Box>
      </Container>
      <Box
        sx={{
          bgcolor: "#6a58c6",
          py: 5,
          color: "white",
          textAlign: "center",
          mt: 5,
          borderRadius: "13px",
        }}
      >
        <Container maxWidth="lg">
          <Typography variant={isMobile ? "h5" : "h4"} fontWeight="bold" mb={2}>
            Get Access to Latest Innovation Reports
          </Typography>
          <Button
            variant="contained"
            sx={{
              bgcolor: "black",
              color: "white",
              padding: "12px 40px",
              fontSize: "1.2rem",
            }}
            onClick={handleOpen}
          >
            Join Now
          </Button>
        </Container>
      </Box>

      {/* Form Popup */}
      {openCommunity && (
        <Form
          open={openCommunity}
          onClose={handleClose}
          category="R&D Report Access"
        />
      )}
    </Box>
  );
};

export default OngoingProjects;
