import { useState } from "react";
import axios from "axios";
import {
  TextField,
  Button,
  Box,
  Typography,
  Paper,
  Grid,
  TextareaAutosize,
  CircularProgress,
  Alert,
} from "@mui/material";
import { SUBMIT_FORM } from "../../api/question";
import form from "../../images/Form.jpg";

export default function ContactForm() {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    companyName: "",
    companyEmail: "",
    phoneNumber: "",
    query: "",
  });

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({ type: "", text: "" });

  const validateForm = () => {
    const newErrors = {};
    const { firstName, lastName, companyName, companyEmail, phoneNumber, query } = formData;

    if (!firstName.trim() || firstName.length < 2)
      newErrors.firstName = "First name must be at least 2 characters";
    if (!lastName.trim() || lastName.length < 2)
      newErrors.lastName = "Last name must be at least 2 characters";
    if (!companyName.trim()) newErrors.companyName = "Company name is required";
    if (!companyEmail.trim() || !/\S+@\S+\.\S+/.test(companyEmail))
      newErrors.companyEmail = "Enter a valid email address";
    if (!phoneNumber.trim() || !/^\d{10}$/.test(phoneNumber))
      newErrors.phoneNumber = "Phone number must be 10 digits";
    if (!query.trim() || query.length < 10)
      newErrors.query = "Query must be at least 10 characters";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    if (errors[name]) setErrors((prev) => ({ ...prev, [name]: "" }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage({ type: "", text: "" });
    if (!validateForm()) return;
    setLoading(true);

    try {
      const token = localStorage.getItem("token");
      await axios.post(SUBMIT_FORM, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      setMessage({ type: "success", text: "Your query has been submitted successfully!" });
      setFormData({
        firstName: "",
        lastName: "",
        companyName: "",
        companyEmail: "",
        phoneNumber: "",
        query: "",
      });
    } catch {
      setMessage({ type: "error", text: "Failed to submit. Please try again." });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{
      margin: "auto",
      padding: { xs: "16px", md: "24px" },
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      background: "#fff"
    }}>
      <Paper sx={{
        width: { xs: "100%", md: "60%" },
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        alignItems: "stretch",
        borderRadius: "8px",
        overflow: "hidden",
        height: {md: '700px' , sm: '100%'}
      }}>
        {/* Image Section */}
        <Box component="img" src={form} alt="Contact Us" sx={{ width: { xs: "100%", md: "50%" }, height: "100%" }} />

        {/* Form Section */}
        <Box sx={{
          width: { xs: "100%", md: "50%" },
          padding: "24px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          bgcolor: "#f9f9f9",
        }}>
          <Typography variant="h5" fontWeight="bold" mb={1}>
            Got questions? We've got answers.
          </Typography>
          <Typography variant="body1" color="text.secondary" mb={3}>
            Get in touch with us for more information on any of the products or services we offer.
          </Typography>

          {message.text && (
            <Alert severity={message.type} sx={{ mb: 2 }}>{message.text}</Alert>
          )}

          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              {['firstName', 'lastName', 'companyName', 'companyEmail', 'phoneNumber'].map((field, index) => (
                <Grid item xs={12} md={field === 'phoneNumber' ? 12 : 6} key={index}>
                  <TextField
                    fullWidth
                    label={field.replace(/([A-Z])/g, ' $1')}
                    name={field}
                    value={formData[field]}
                    onChange={handleChange}
                    required
                    error={!!errors[field]}
                    helperText={errors[field]}
                  />
                </Grid>
              ))}
              <Grid item xs={12}>
                <TextareaAutosize
                  minRows={4}
                  placeholder="Your query*"
                  name="query"
                  value={formData.query}
                  onChange={handleChange}
                  style={{
                    width: "100%",
                    padding: "10px",
                    borderRadius: "4px",
                    border: "1px solid #ccc",
                  }}
                  required
                />
                {errors.query && (
                  <Typography color="error" variant="body2">
                    {errors.query}
                  </Typography>
                )}
              </Grid>
            </Grid>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, bgcolor: "#333", color: "#fff", ":hover": { bgcolor: "#222" } }}
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} sx={{ color: "white" }} /> : "Submit"}
            </Button>
          </form>
        </Box>
      </Paper>
    </Box>
  );
}
