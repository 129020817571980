import { Card, CardContent, CardHeader, CardActions, useMediaQuery } from "@mui/material";
import { Button, Typography, Badge , Box } from "@mui/material";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import pic1 from '../../images/D2.png';
import pic2 from '../../images/D1.png';

export default function Spotlight() {
  const isMobile = useMediaQuery("(max-width: 600px)");

  return (
    <Box sx={{width: '100%', background: "#fff"}}>
    <section style={{ width: "98%", margin: "auto", padding: "48px 16px", background: "#fff" }}>
      <Typography variant="h2" align="center" gutterBottom>
        Spotlight
      </Typography>
      <div
        style={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          gap: "32px",
          flexWrap: "wrap",
          alignItems: "center",
        }}
      >
        {[
          {
            title: "The Future of GEN AI",
            badge: "AI & FUTURE",
            text: "The global GenAI market is projected to reach $100 billion by 2030, driven by its applications in healthcare, finance, and creative industries. However, challenges such as AI bias, ethical concerns, and regulatory issues remain critical. Future developments include multimodal AI, hyper-personalization, and AI-as-a-Service, shaping the next phase of digital transformation.",
            img: pic1,
          },
          {
            title: "Trending Industry By 2030",
            badge: "INDUSTRY TRENDS",
            text: "India is on track to install over 500 GW of non-fossil electricity generation capacity by 2030. The e-commerce industry is projected to grow significantly, reaching $550 billion by 2035. The aerospace sector is evolving rapidly, with global giants like Airbus and Rolls-Royce sourcing components from India as the country moves towards advanced system integration",
            img: pic2,
          },
        ].map((item, index) => (
          <Card
            key={index}
            style={{
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
              width: isMobile ? "100%" : "48%",
              height: '100%'
            }}
          >
            <div
              style={{
                width: isMobile ? "100%" : "33%",
                position: "relative",
                height: '400px'
              }}
            >
              <img
                src={item.img}
                alt={item.title}
                style={{ width: "100%", height: "100%" }}
              />
              <Badge
                style={{
                  backgroundColor: "#10B981",
                  color: "white",
                  padding: "8px 12px",
                  borderRadius: "4px",
                }}
              >
                {item.badge}
              </Badge>
            </div>
            <div
              style={{
                width: isMobile ? "100%" : "67%",
                padding: "24px",
              }}
            >
              <Typography variant="h3"  sx={{ padding: 0, marginBottom: "16px"  }}>{item.title}</Typography> 
              <CardContent>
                <Typography variant="body1" color="textSecondary">
                  {item.text}
                </Typography>
              </CardContent>
              <CardActions>
                <Button href="#" color="primary" endIcon={<ArrowRightIcon />}>
                  Read More
                </Button>
              </CardActions>
            </div>
          </Card>
        ))}
      </div>
    </section>
    </Box>
  );
}
