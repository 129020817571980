import React from "react";
import { useMediaQuery } from "@mui/material";
import axios from "axios";
import { POST_CONTACT } from "../../api/mainPage.js";
import { validateEmail } from "../auth/validateEmail.js";
import { Typography, Button } from "@material-ui/core";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
// import Fade from '@mui/material/Fade';
import Loader from "./Loader.jsx";
import Question from "../Question/index";
import Footer from "../About/Footer.js";
const DOMAIN = process.env.REACT_APP_DOMAIN;

const Contact = () => {
  const currencies = [
    {
      value: "General",
      label: "General",
    },
    {
      value: "Webite",
      label: "Website",
    },
    {
      value: "Payment",
      label: "Payment",
    },
  ];

  const [clicked, setClicked] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [fName, setFName] = React.useState("");
  const [lName, setLName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [wNumber, setWnumber] = React.useState("");
  const [iType, setIType] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [resume, setResume] = React.useState(null);
  const [emailError, setEmailError] = React.useState("");
  const [response, setResponse] = React.useState("");
  const [numberError, setNumberError] = React.useState("");

  const isMobile = useMediaQuery("(max-width:838px)");

  const validate = () => {
    if (fName.length <= 0 && lName.length <= 0) return false;
    else if (wNumber.length !== 10) return false;
    else if (!validateEmail(email)) return false;
    else return true;
  };

  const handleApply = () => {
    if (validate()) {
      const data = new FormData();
      data.append("firstname", fName);
      data.append("lastname", lName);
      data.append("email", email);
      data.append("whatsappno", wNumber);
      data.append("issuetype", iType);
      data.append("description", description);
      if (resume) data.append("file", resume);

      axios
        .post(`${DOMAIN}/api/postcontact`, data)
        .then((response) => {
          console.log("success");
          console.log(response);
          setClicked(true);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      console.log("Internal Server Error!");
      setResponse("Entered Wrong Data");
      alert(response);
    }

    if (validate()) {
      const data = new FormData();
      data.append("fName", fName);
      data.append("lName", lName);
      data.append("email", email);
      data.append("wNumber", wNumber);
      data.append("iType", iType);
      data.append("description", description);
      if (resume) data.append("resume", resume);

      setLoading(true);
      axios
        .post(POST_CONTACT, data)
        .then((response) => {
          console.log(response);
          setLoading(false);
          setClicked(true);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setResponse("Entered Wrong Data");
      alert(response);
    }
  };

  return (
    <div
      className="fullPage"
      style={{
        margin: 0,
        background: "#fff",
      }}
    >
      <div
        style={{
          marginTop: "30px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Box
          style={{
            width: "50%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            variant={!isMobile ? "h2" : "h4"}
            style={{
              color: "#000",
              fontWeight: "bold",
              fontFamily: "sans-serif",
              marginBottom: "10px",
              textAlign: "center",
            }}
          >
            {" "}
            Contact Us{" "}
          </Typography>
          <Typography
            variant="subtitle1"
            style={{
              color: "#000",
              fontFamily: "sans-serif",
              marginBottom: "10px",
              textAlign: "center",
              fontSize: isMobile ? "15px" : "24px",
            }}
          >
            Reach out to us and raise any issue you are facing during
            navigation, checkout or anything challenging.{" "}
          </Typography>
        </Box>
        <Question />
      </div>
      <Footer />
    </div>
  );
};

export default Contact;
