import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Fab from "@material-ui/core/Fab";
import IconButton from "@material-ui/core/IconButton";
// import PeopleIcon from "@material-ui/icons/People";
import PlayCircleFilledWhiteIcon from "@mui/icons-material/PlayCircleFilledWhite";
import EventIcon from "@material-ui/icons/Event";
import WorkIcon from "@material-ui/icons/Work";
import { FaHandshake, FaInfo } from "react-icons/fa";
import "../../stylesheets/Navbar.css";
import { Link } from "react-router-dom";
import Toolbar from "@material-ui/core/Toolbar";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import UpwardDrawer from "../auth/UpwardDrawer";
import { MdCardMembership } from "react-icons/md";
//import Typography from "@material-ui/core/Typography";
const useStyles = makeStyles((theme) => ({
  appBar: {
    top: "auto",
    bottom: 0,
    backgroundColor: "black",
    marginTop: theme.spacing(10),
  },
  grow: {
    flexGrow: 1,
  },
  fabButton: {
    position: "absolute",
    zIndex: 1,
    top: 4,
    left: 0,
    right: 0,
    margin: "0 auto",
    fontSize: "30px",
    backgroundColor: "black",
  },
  drawerbtn: {
    backgroundColor: "black",
  },
}));

function NavbarBottomM() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton aria-label="event">
            <Link to="/partnerwithus">
              <FaHandshake className="bottom_icon" />
            </Link>
          </IconButton>
          <IconButton aria-label="event">
            <Link to="/member">
              <MdCardMembership className="bottom_icon" />
            </Link>
          </IconButton>
          <Fab className={classes.fabButton}>
            <UpwardDrawer className={classes.drawerbtn} />
          </Fab>

          <div className={classes.grow} />
          <IconButton aria-label="event">
            <Link to="/about">
              <LibraryBooksIcon className="bottom_icon" />
            </Link>
          </IconButton>
          <IconButton aria-label="event">
            <Link to="/">
              <PlayCircleFilledWhiteIcon className="bottom_icon" />
            </Link>
          </IconButton>
          
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default NavbarBottomM;
