import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import { CONNECT_WITH_US } from "../../api/connectWithus";
import Form from "../Form";

const ConnectWithUs = () => {
  const [open, setOpen] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [formData, setFormData] = useState({
    fullName: "",
    organization: "",
    designation: "",
    email: "",
    phone: "",
    purpose: "",
  });
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const validate = () => {
    let tempErrors = {};
    tempErrors.fullName = formData.fullName ? "" : "Full Name is required";
    tempErrors.organization = formData.organization
      ? ""
      : "Organization is required";
    tempErrors.designation = formData.designation
      ? ""
      : "Designation is required";
    tempErrors.email = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)
      ? ""
      : "Enter a valid email";
    tempErrors.phone = /^\d{10}$/.test(formData.phone)
      ? ""
      : "Enter a valid 10-digit phone number";
    tempErrors.purpose = formData.purpose ? "" : "Purpose is required";
    setErrors(tempErrors);
    return Object.values(tempErrors).every((x) => x === "");
  };

  const handleSubmit = async () => {
    if (!validate()) return;
    try {
      await fetch(CONNECT_WITH_US, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(formData),
      });
      setSubmitted(true);
      setTimeout(() => {
        setOpen(false);
      }, 6000);
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={() => setOpen(true)}
        sx={{
          marginTop: "-20px",
          padding: "10px 40px 10px 40px",
          marginBottom: "40px",
          color: "lightblue",
          fontWeight: "bold",
          color: "#D2EFF1",
          backgroundColor: "#002856",
          borderRadius: "50px",
        }}
      >
        Connect With Us
      </Button>
      <Form
        open={open}
        onClose={() => setOpen(false)}
        category={"Main Page - Connect"}
      />
      {/* <Dialog open={open} onClose={() => setOpen(false)} >
        <DialogTitle>Connect With Us</DialogTitle>
        <DialogContent>
          {submitted ? (
            <Typography>
              Thanks for showing your interest, our team will reach out to you
              shortly.
            </Typography>
          ) : (
            <>
              <TextField
                label="Full Name"
                name="fullName"
                fullWidth
                margin="dense"
                onChange={handleChange}
                error={!!errors.fullName}
                helperText={errors.fullName}
              />
              <TextField
                label="Organization"
                name="organization"
                fullWidth
                margin="dense"
                onChange={handleChange}
                error={!!errors.organization}
                helperText={errors.organization}
              />
              <TextField
                label="Designation"
                name="designation"
                fullWidth
                margin="dense"
                onChange={handleChange}
                error={!!errors.designation}
                helperText={errors.designation}
              />
              <TextField
                label="Email ID"
                name="email"
                fullWidth
                margin="dense"
                onChange={handleChange}
                error={!!errors.email}
                helperText={errors.email}
              />
              <TextField
                label="Phone Number"
                name="phone"
                fullWidth
                margin="dense"
                onChange={handleChange}
                error={!!errors.phone}
                helperText={errors.phone}
              />
              <TextField
                label="Purpose"
                name="purpose"
                fullWidth
                margin="dense"
                onChange={handleChange}
                error={!!errors.purpose}
                helperText={errors.purpose}
              />
            </>
          )}
        </DialogContent>
        <DialogActions>
          {!submitted && (
            <Button onClick={handleSubmit} color="primary" variant="contained">
              Submit
            </Button>
          )}
        </DialogActions>
      </Dialog> */}
    </>
  );
};

export default ConnectWithUs;
