import { useState, useEffect } from "react";
import {
  TextField,
  Select,
  MenuItem,
  Button,
  Typography,
  Box,
  Container,
  Paper,
  CircularProgress,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import axios from "axios";
import Footer from "../About/Footer";
import Form from "../Form";

const DOMAIN = process.env.REACT_APP_DOMAIN;

export default function MembershipForm() {
  const [designation, setDesignation] = useState("student");
  const [otherDesignation, setOtherDesignation] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contactNo, setContactNo] = useState("");
  const [loading, setLoading] = useState(false);
  const [resp, setResp] = useState("");
  const [openPopUp, setOpenPopUp] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [contactNoError, setContactNoError] = useState("");

  useEffect(() => {
    if (!email) setEmailError("");
    else
      setEmailError(validateEmail(email) ? "" : "Please enter a valid email.");
  }, [email]);

  useEffect(() => {
    if (!contactNo) setContactNoError("");
    else
      setContactNoError(
        contactNo.length === 10 ? "" : "Please enter a valid contact number."
      );
  }, [contactNo]);

  const validateEmail = (email) => {
    return /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email);
  };

  const handleSubmit = async () => {
    if (
      !name ||
      !email ||
      !contactNo ||
      (designation === "other" && !otherDesignation)
    ) {
      setResp("All fields are required");
      setOpenPopUp(true);
      return;
    }
    if (emailError || contactNoError) {
      setResp(emailError || contactNoError);
      setOpenPopUp(true);
      return;
    }

    const data = {
      name,
      email,
      contactNo,
      designation: designation === "other" ? otherDesignation : designation,
    };

    setLoading(true);
    try {
      const res = await axios.post(`${DOMAIN}/new/membership`, data, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      });
      setResp(res.data.message);
      setOpenPopUp(true);
      setName("");
      setEmail("");
      setContactNo("");
      setDesignation("student");
      setOtherDesignation("");
    } catch (err) {
      setResp("Some error occurred");
      setOpenPopUp(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: { lg: "89.5vh", xs: "100%" },
          background: "#ffff",
          marginTop: { xs: 2, lg: 2 },
          marginBottom: { xs: 10, lg: 0 },
        }}
      >
        <Form
          open={true}
          onClose={""}
          category={"Main Apply as Mentor"}
          type=""
        />
        {/* <Box
          sx={{
            display: "flex",
            width: { lg: "70%", xs: "90%" },
            borderRadius: "10px",
            flexDirection: { lg: "row", xs: "column-reverse" },
            gap: { lg: 0, xs: 2 },
            marginTop: { xs: 2, lg: 2 },
            background: "#fff",
          }}
        >
          <Box
            sx={{
              width: { xs: "100%", lg: "50%" },
              backgroundColor: "#fff",
              color: "white",
              padding: 4,
              borderTopLeftRadius: "10px",
              borderBottomLeftRadius: "10px",
              borderTopRightRadius: { xs: "10px", lg: 0 },
              borderBottomRightRadius: { xs: "10px", lg: 0 },
              boxShadow: 3,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "center",
                //   backgroundColor: "#8257E6",
                //   backgroundColor: 'rgb(143,130,212)',
                background:
                  "linear-gradient(164deg, rgba(143,130,212,1) 38%, rgba(106,88,198,1) 100%, rgba(255,255,255,1) 100%)",
                padding: 4,
                borderRadius: "10px",
              }}
            >
              {[
                {
                  title: "Apply for Membership",
                  description:
                    "Start with Markoknow vast community to begin with",
                },
                {
                  title: "Get Accepted",
                  description: "Get accepted by the community for onboarding",
                },
                {
                  title: "Complete your Onboarding",
                  description:
                    "Complete your onboarding process to be a part of this exclusive community",
                },
              ].map((step, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    alignItems: "flex-start",
                    gap: 1,
                    mb: 3,
                    flexDirection: "column",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      bgcolor: "rgba(255, 255, 255, 0.2)",
                      p: 1,
                      borderRadius: "50%",
                    }}
                  >
                    <CheckIcon fontSize="small" />
                  </Box>
                  <Typography
                    variant="h6"
                    sx={{ fontSize: "1.125rem", fontWeight: 600 }}
                  >
                    {step.title}
                  </Typography>
                  <Typography variant="body2">{step.description}</Typography>
                </Box>
              ))}
            </Box>
          </Box>

          <Container
            component={Paper}
            elevation={3}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              p: 4,
              width: { xs: "100%", lg: "60%" },
              borderTopRightRadius: "10px",
              borderBottomRightRadius: "10px",
              borderTopLeftRadius: { xs: "10px", lg: 0 },
              borderBottomLeftRadius: { xs: "10px", lg: 0 },
            }}
          >
            {loading ? (
              <CircularProgress size={45} />
            ) : (
              <Box sx={{ width: "100%", maxWidth: 400 }}>
                <Typography variant="h5" textAlign="center" fontWeight={600}>
                  Apply as Member
                </Typography>
                <Typography
                  variant="body2"
                  textAlign="center"
                  color="text.secondary"
                  mb={3}
                >
                  Fill out the form below to start your membership application
                </Typography>

                <TextField
                  fullWidth
                  label="Full Name"
                  variant="outlined"
                  margin="normal"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <TextField
                  fullWidth
                  label="Email"
                  variant="outlined"
                  margin="normal"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  error={!!emailError}
                  helperText={emailError}
                />
                <TextField
                  fullWidth
                  label="Contact Number"
                  variant="outlined"
                  margin="normal"
                  value={contactNo}
                  onChange={(e) => setContactNo(e.target.value)}
                  error={!!contactNoError}
                  helperText={contactNoError}
                />
                <Select
                  fullWidth
                  value={designation}
                  onChange={(e) => setDesignation(e.target.value)}
                  sx={{
                    marginTop: 2,
                  }}
                >
                  <MenuItem value="student">Student</MenuItem>
                  <MenuItem value="professional">Professional</MenuItem>
                  <MenuItem value="other">Other</MenuItem>
                </Select>
                {designation === "other" && (
                  <TextField
                    fullWidth
                    label="Please specify"
                    variant="outlined"
                    margin="normal"
                    value={otherDesignation}
                    onChange={(e) => setOtherDesignation(e.target.value)}
                  />
                )}
                <Button
                  fullWidth
                  variant="contained"
                  sx={{
                    mt: 2,
                    bgcolor: "#8257E6",
                    ":hover": { bgcolor: "#6D48C4" },
                    fontSize: "12px",
                  }}
                  onClick={handleSubmit}
                >
                  Submit Application
                </Button>
              </Box>
            )}
          </Container>
        </Box> */}
      </Box>
      <Footer />
    </>
  );
}
