import React from 'react';
import { Box, Typography, Container, Button, Grid } from '@mui/material';
import MarkoknowPage from './AboutMarkoknow';
import VisionMarkoknow from './VisionMarkoknow';
import LifeSection from './LifeAtMarkoknow';
import Footer from '../About/Footer';
const AboutUs = () => {
  return (
    <Box sx={{background: '#fff' , width: '100%'}}>
         <MarkoknowPage/>

         <VisionMarkoknow/>

         <LifeSection/>

         <Footer/>
    </Box>
  );
};

export default AboutUs;