import React from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Container, Grid, Typography, Button, Box } from "@mui/material";
import img from '../../images/14.jpg'

const ReportsAccess = () => {
  return (
    <main style={{ flexGrow: 1 , backgroundColor: '#fff' }}>
      <Container maxWidth="lg" sx={{ px: 2, py: { xs: 8, md: 12 } }}>
        <Grid container spacing={4} alignItems="center">
          {/* Left Column - Image */}
          <Grid item xs={12} md={6} display="flex" justifyContent="center">
            <Box position="relative" width={"100%"} maxWidth={500}>
              <img
                src={img}
                alt="Person with market report"
                width={500}
                height={600}
                style={{ maxWidth: "100%", height: "auto" }}
              />
            </Box>
          </Grid>
          
          {/* Right Column - Content */}
          <Grid item xs={12} md={6}>
            <Box sx={{ textAlign: { xs: "center", md: "left" } }}>
              <Typography variant="h4" fontWeight="bold" gutterBottom>
                Get Access to our Latest Reports
              </Typography>
              <Typography variant="body1" color="text.secondary" paragraph>
                Join our surveys and get access to our latest reports on trending markets, market analysis, and what
                the future looks like.
              </Typography>
              
              <Box sx={{ mt: 3 }}>
                {[
                  "Updates on Latest Trends",
                  "Early Access to Reports",
                  "Multiple Technology & Trends upcoming covered"
                ].map((text, index) => (
                  <Box key={index} display="flex" alignItems="center" gap={1.5} mb={1.5}>
                    <CheckCircleIcon sx={{ color: "green", fontSize: 32 }} />
                    <Typography variant="body1">{text}</Typography>
                  </Box>
                ))}
              </Box>
              
              <Box sx={{ mt: 4 }}>
                <Button
                  href="/access"
                  variant="contained"
                  sx={{
                    backgroundColor: "#7B5CD6",
                    color: "white",
                    padding: "12px 24px",
                    borderRadius: "999px",
                    fontSize: "1rem",
                    "&:hover": { backgroundColor: "#6A4DC9" },
                  }}
                >
                  Get Access
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </main>
  );
};

export default ReportsAccess;