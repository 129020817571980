import React, { useState, useEffect } from "react";
import { Grid, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import axios from "axios";
import wp from "./ourTeam.png";
import svcDelhi from "./svcDelhi.jpg";
import VNIT from "./VNIT_logo.jpeg";
import IITB from "./IITB.svg";
import DTU from "./DTU.jpg";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import DSC from "./DSC.jpg";
// import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import { Carousel } from "3d-react-carousal";
// import Video from "../courses/video";
import { Link } from "react-router-dom";
import CountUp from "react-countup";
import Footer from "../About/Footer";
import ImageSetup from "./ImageSetup";
import { GET_B1, GET_B2, GET_B3 } from "../../api/mainPage";
import Insights from "../Insights";
import ConnectWithUs from "../ContactWithUs";
import { Box } from "@mui/material";
import Spotlight from "../Spotlight";
import Question from "../Question/index";
import Form from "../Form";

// const DOMAIN = process.env.REACT_APP_DOMAIN;
const u = makeStyles({
  body: {
    backgroundColor: "#fff",
    color: "white",
    height: "60vh",
    paddingTop: "100px",
    "@media only screen and (max-width: 960px)": {
      height: "110vh",
    },
    "@media only screen and (max-width: 480px)": {
      height: "100vh",
      paddingTop: "20px",
    },
  },
  body2: {
    backgroundColor: "#6a58c6",
    color: "white",
    height: "50vh",
    paddingTop: "50px",
    paddingBottom: "50px",
    marginBottom: "50px",
    "@media only screen and (max-width: 960px)": {
      height: "100vh",
    },
    "@media only screen and (max-width: 480px)": {
      height: "100%",
      paddingTop: "20px",
      marginBottom: "50px",
    },
  },
  container: {
    marginRight: "100px",
    marginLeft: "100px",
    "@media only screen and (max-width: 960px)": {
      marginRight: "10px",
      marginLeft: "10px",
    },
    "@media only screen and (max-width: 480px)": {
      marginRight: "50px",
      marginLeft: "50px",
    },
  },
  topBox: {
    disply: "flex",
    flexDirection: "row",
    "@media only screen and (max-width: 480px)": {
      disply: "flex",
      flexDirection: "column",
    },
  },
  mainVideo: {
    marginTop: "-100px",
    marginLeft: "100px",
    display: "flex",
    borderRadius: "25px",
    overflow: "hidden",
    marginBottom: "20vh",

    "@media only screen and (max-width: 960px)": {
      width: "500px",
    },
    "@media only screen and (max-width: 480px)": {
      marginLeft: "0px",
      paddingBottom: "20vh",
      width: "400%",
      height: "400%",
      marginTop: "-45px",
    },
  },
  heading: {
    lineHeight: "60px",
    marginBottom: "5px",
    disply: "flex",
    color: "#D2EFF1",
    // fontFamily: "Garet ExtraBold",
    textAlign: "left",
    fontSize: "52px",
    fontWeight: "bold",
    width: "100%",
    // height: '600px',
    "@media only screen and (max-width: 480px)": {
      fontSize: "30px",
      marginBottom: "20px",
      flexDirection: "row",
    },
  },
  subheading: {
    lineHeight: "2",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "20px",
    width: "700px",
    marginTop: "20px",
    "@media only screen and (max-width: 480px)": {
      marginTop: "5px",
      marginLeft: "0px",
      marginBottom: "10px",
      width: "100%",
      textAlign: "none",
      fontSize: "15px",
    },
  },
  heading2: {
    marginBottom: "10px",
    disply: "flex",
    color: "black",
    textAlign: "center",
    fontSize: "52px",
    fontWeight: "bold",
    width: "100%",
  },
  cardText: {
    fontSize: "32px",
    paddingLeft: "20px",
    paddingTop: "10px",
    color: "#221857",
    fontWeight: "bold",
    "@media only screen and (max-width: 480px)": {
      fontSize: "20px",
    },
  },
  cardHeading: {
    paddingTop: "20px",
    fontSize: "80px",
    color: "lightblue",
    "@media only screen and (max-width: 480px)": {
      fontSize: "50px",
    },
  },
  cardsubHeading: {
    fontSize: "55px",
    color: "#221857",
    fontWeight: "bold",
    marginTop: "20px",
    borderRadius: "10px",
    "@media only screen and (max-width: 480px)": {
      fontSize: "30px",
    },
  },
  ourTeam1: {
    margin: "50px 0px 50px 50px",
    "@media only screen and (max-width: 960px)": {
      margin: "100px 0px 0px 100px",
    },
    "@media only screen and (max-width: 480px)": {
      margin: "50px 50px 0px 50px",
    },
  },
  ourTeam2: {
    marginTop: "50px",
    marginBottom: "50px",
    "@media only screen and (max-width: 480px)": {
      marginBottom: "5px",
      marginLeft: "30px",
    },
  },
  ourTeamImage: {
    width: "100%",
    height: "100%",
    boxShadow: "10px 10px #6A58c6",
    "@media only screen and (max-width: 960px)": {
      height: "80%",
      width: "80%",
    },
    "@media only screen and (max-width: 480px)": {
      height: "100%",
      width: "100%",
    },
  },
  btnRoadmap: {
    backgroundColor: "#89e3db",
    height: "45px",
    width: "80px",
    paddingTop: "10px",
    borderRadius: "20px",
    fontSize: "20px",
    marginRight: "10px",
    marginLeft: "10px",
    color: "#221857",
    fontWeight: "bold",
    "&:hover": {
      backgroundColor: "#221857",
      color: "white",
      transform: "scale(1.1)",
    },
    "@media only screen and (max-width: 480px)": {
      display: "none",
    },
  },
  textRoadmap: {
    color: "#221857",
    fontWeight: "bold",
    fontSize: "20px",
    paddingTop: "7px",
    "@media only screen and (max-width: 480px)": {
      fontSize: "12px",
    },
  },
  Internships: {
    backgroundColor: "#251e4f",
    width: "90%",
    marginRight: "100px",
    marginLeft: "100px",
    borderRadius: "20px",
    marginBottom: "100px",
    "@media only screen and (max-width: 480px)": {
      width: "90%",
      marginLeft: "20px",
      marginBottom: "10px",
    },
    "@media only screen and (max-width: 960px)": {
      width: "90%",
      marginBottom: "10px",
      marginLeft: "25px",
    },
  },
  carousel_event: {
    height: "auto",
    backgroundColor: "white",
    borderRadius: "30px",
    textAlign: "center",
    paddingTop: "20px",
    "@media only screen and (max-width: 480px)": {
      height: "auto",
      maxHeight: "300px",
    },
  },
  carouselCompanies: {
    height: "200px",
    backgroundColor: "#6A58c6",
    marginRight: "100px",
    marginLeft: "100px",
    borderRadius: "10px",
    "@media only screen and (max-width: 480px)": {
      marginRight: "30px",
      marginLeft: "30px",
    },
  },
  faq: {
    marginLeft: "200px",
    marginBottom: "100px",
    "@media only screen and (max-width: 900px)": {
      marginLeft: "0px",
    },
  },
  roads: {
    backgroundColor: "#D2EFF1",
    minHeight: "80px",
    height: "auto",
    width: "80%",
    marginBottom: "20px",
    borderRadius: "50px",
    color: "#221857",
    "@media only screen and (max-width: 900px)": {
      width: "100%",
      fontSize: "10px",
      marginRight: "10px",
      marginLeft: "10px",
    },
  },
  roadsText: {
    backgroundColor: "#D2EFF1",
    width: "100%",
    height: "100px",
    marginBottom: "20px",
    borderRadius: "20px",
    color: "#221857",
    backgroundColor: "#D2EFF1",
    overflow: "hidden",
    "@media only screen and (max-width: 900px)": {
      fontSize: "10px",
    },
  },
  hideButton: {
    "@media only screen and (max-width: 900px)": { display: "none" },
  },
  showButton: {
    display: "none",
    "@media only screen and (max-width: 900px)": { display: "block" },
  },
  communityText: {
    height: "300px",
    marginBottom: "20px",
    borderTopRightRadius: "12px",
    borderTopLeftRadius: "12px",
    borderBottomLeftRadius: "12px",
    color: "#221857",
    "@media only screen and (max-width: 900px)": {
      height: "200px",
    },
  },
  carouselButtons: {
    borderRadius: "50%",
    backgroundColor: "lightblue",
    height: "100px",
    color: "black",
    width: "100px",
    "&:hover": {
      transform: "scale(1.1)",
    },
    "@media only screen and (max-width: 900px)": {
      height: "10px",
      width: "10px",
    },
  },
  mainTestimonial: {
    marginBottom: "50px",
    marginRight: "10px",
    marginLeft: "10px",
    "@media only screen and (max-width:900px)": {
      marginBottom: "30px",
      marginRight: "10px",
      marginLeft: "10px",
    },
  },
  headingTestimonial: {
    marginTop: "30px",
    marginLeft: "30px",
    textAlign: "center",
    fontSize: "30px",
    "@media only screen and (max-width: 900px)": {
      marginTop: "10px",
      fontSize: "15px",
      textAlign: "left",
    },
  },
  subTestimonial: {
    marginTop: "100px",
    justifyContent: "space-between",
    "@media only screen and (max-width: 900px)": {
      marginTop: "30px",
    },
  },
  personTestimonial: {
    fontStyle: "italic",
    fontWeight: "100",
    backgroundColor: "#221857",
    color: "white",
    border: "1px solid #221857",
    borderRadius: "13px",
    marginTop: "-7px",
    padding: "4px 4px 4px 4px",
    "@media only screen and (max-width: 900px)": {
      fontSize: "19px",
    },
  },
  imageTestimonial: {
    height: "120px",
    width: "120px",
    borderRadius: "20%",
    "@media only screen and (max-width: 900px)": {
      height: "auto",
      width: "auto",
      maxHeight: "100px",
      maxWidth: "100px",
    },
  },
  imageTestimonialDesign: {
    height: "70px",
    paddingBottom: "20px",
    width: "100px",
    display: "flex",
    alighItems: "left",
    borderRadius: "20%",
    "@media only screen and (max-width: 900px)": {
      height: "auto",
      width: "auto",
      maxHeight: "100px",
      maxWidth: "100px",
    },
  },
  mentorshipText: {
    color: "white",
    fontSize: "20px",
    textAlign: "left",
    "@media only screen and (max-width: 900px)": {
      marginLeft: "0px",
      marginRight: "0px",
      textAlign: "left",
    },
  },
  mentorshipList: {
    color: "white",
    fontSize: "20px",
    marginLeft: "50px",
    marginRight: "50px",
    marginTop: "175px",
    "@media only screen and (max-width: 960px)": {
      textAlign: "left",
      marginTop: "50px",
    },
    "@media only screen and (max-width: 480px)": {
      marginTop: "20px",
      textAlign: "left",
    },
  },
});

function NewHome() {
  const classes = u();
  //  comp
  const newlist = [svcDelhi, VNIT, IITB, DTU, DSC];
  // rodmap
  const [road1, setRoad1] = useState(false);
  const [road2, setRoad2] = useState(false);
  const [road3, setRoad3] = useState(false);
  const [road4, setRoad4] = useState(false);
  const [road5, setRoad5] = useState(false);

  const [b1, setB1] = useState(null);
  const [b2, setB2] = useState(null);
  const [b3, setB3] = useState(null);
  const theme = useTheme();

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.down("md"));

  const getB1 = () => {
    axios
      .get(GET_B1)
      .then((res) => {
        setB1(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getB2 = () => {
    axios
      .get(GET_B2)
      .then((res) => {
        setB2(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const getB3 = () => {
    axios
      .get(GET_B3)
      .then((res) => {
        setB3(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    getB1();
    getB2();
    getB3();
  }, []);

  return (
    <>
      <div className={classes.body}>
        <div className={classes.container}>
          <Grid container spacing={2} style={{ marginBottom: "100px" }}>
            <Grid item lg={6} md={6} xs={12}>
              <div className={classes.heading}>
                <p
                  style={{
                    textAlign: "left",
                    color: "#002856",
                    fontFamily: "Graphik",
                    fontWeight: 700,
                  }}
                >
                  {b1?.title ||
                    "Platform for Startup Enthusiasts, Founders and Investors to Connect and Grow"}
                </p>
                <div className={classes.subheading}>
                  <p
                    style={{
                      textAlign: "left",
                      marginBottom: "50px",
                      color: "#000",
                    }}
                  >
                    {b1?.subTitle ||
                      "50,000+ member platform for Startups and Enthusiasts to grow, network and work towards their startup"}
                  </p>
                </div>
              </div>
              <div
                style={{
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "flex-start",
                  gap: "20px",
                }}
              >
                <Link to={"/login"}>
                  <Button
                    variant="large"
                    style={{
                      marginTop: "-20px",
                      padding: "10px 40px 10px 40px",
                      marginBottom: "40px",
                      color: "lightblue",
                      fontWeight: "bold",
                      color: "#D2EFF1",
                      backgroundColor: "#002856",
                      borderRadius: "50px",
                    }}
                  >
                    {b1?.buttonName || "Explore"}
                  </Button>
                </Link>
                <Box>
                  <ConnectWithUs />
                </Box>
              </div>
            </Grid>
            {/* <div className={classes.mainVideo}>
              {b1 ? (
                <>
                  <Video link={b1?.videoLink} />
                </>
              ) : null}
            </div> */}
            <Grid
              item
              lg={5}
              md={5}
              xs={12}
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {/* <div
                className={classes.carousel_event}
                style={{
                  overflow: "hidden",
                  maxHeight: "1000px",
                  backgroundColor: "#221857",
                  width: isSmallScreen ? "100%" : "40vw",
                  marginLeft: isSmallScreen ? "0px" : "4vw",
                  marginTop: isSmallScreen ? "-5vh" : "-10vh",
                }} // Adjust the maxHeight as needed
              >
                <Carousels
                  indicators={true}
                  navButtonsAlwaysVisible={isSmallScreen ? false : true}
                  fullHeightHover={false}
                  animation={"slide"}
                  swipe={true}
                  stopAutoPlayOnHover={true}
                  navButtonsProps={{
                    style: {
                      borderRadius: "50%",
                      backgroundColor: "#6A58c6",
                      height: "50px",
                      color: "black",
                      width: "50px",
                      marginTop: "25px",
                      "&:hover": {
                        transform: "scale(1.1)",
                      },
                    },
                  }}
                >
                  {b4?.map((item, index) => (
                    <div
                      key={item.id}
                      className={classes.mainTestimonial}
                      style={{ height: "100%", overflow: "hidden" }}
                    >
                      <Grid container>
                        <Grid item sm={3}>
                          <img
                            src={img4}
                            alt={item.name} // Add alt text for accessibility
                            style={{
                              marginLeft: isSmallScreen ? "10vw" : "0vw",
                            }}
                            className={classes.imageTestimonialDesign}
                          />
                        </Grid>
                        <Grid item sm={9}>
                          <Typography
                            style={{
                              color: "white",
                              fontSize: "20px",
                              fontWeight: "500",
                              textAlign: "left",
                              marginLeft: isSmallScreen ? "10vw" : "5px",
                            }}
                          >
                            {item?.testimonial}
                          </Typography>
                        </Grid>

                        <Grid
                          item
                          sm={2.3}
                          style={{
                            paddingTop: "4vh",
                            paddingLeft: "5vw",
                          }}
                        >
                          <img
                            src={index === 0 ? img1 : index === 1 ? img2 : img3}
                            alt={item.name} // Add alt text for accessibility
                            className={classes.imageTestimonial}
                            style={{
                              marginLeft: isSmallScreen ? "4rem" : "",
                            }}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={4}
                          style={{
                            paddingTop: isSmallScreen ? "1vh" : "12vh",
                            paddingLeft: "5px",
                          }}
                        >
                          <Typography
                            style={{
                              color: "white",
                              fontWeight: "700",
                              fontSize: "22px",
                              textAlign: isSmallScreen ? "center" : "left",
                            }}
                          >
                            {item?.name}
                          </Typography>
                        </Grid>
                      </Grid>
                    </div>
                  ))}
                </Carousels>
              </div>   */}
              <ImageSetup />
            </Grid>
          </Grid>
        </div>
      </div>

      <div
        container
        style={{
          backgroundColor: "#fff",
          paddingTop: isMediumScreen ? "30vh" : isSmallScreen ? "30vh" : "0vh",
          paddingBottom: "50px",
        }}
      >
        <Grid container>
          <div
            className={classes.heading2}
            style={{
              marginTop: isLargeScreen
                ? "0vh"
                : isSmallScreen
                ? "-10vh"
                : "20vh",
              marginBottom: "50px",
            }}
          >
            Our Community
          </div>
          <Grid
            container
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
            }}
          >
            <Grid item lg={2} md={5} xs={5}>
              <Grid
                className={classes.communityText}
                style={{ backgroundColor: "#b1a6e9" }}
              >
                <p className={classes.cardText} style={{ color: "black" }}>
                  01
                </p>
                <p className={classes.cardText} style={{ color: "black" }}>
                  {b2?.C1Heading}
                </p>
                {/* <div style={{marginLeft: '105px', marginTop: '19px'}}>
    <img src={svg1}/>
    </div> */}
              </Grid>
              <div
                style={{
                  marginBottom: "50px",
                  fontWeight: "500",
                  fontSize: "20px",
                }}
              >
                {b2?.C1Description}
              </div>
            </Grid>
            <Grid item lg={2} md={5} xs={5}>
              <Grid
                className={classes.communityText}
                style={{
                  backgroundColor: "#89e3db",
                }}
              >
                <p className={classes.cardText}>02</p>
                <p className={classes.cardText}>{b2?.C2Heading}</p>
                {/* <div style={{marginLeft: '102px', marginTop: '6px'}}>
    <img src={svg2}/>
    </div> */}
              </Grid>
              <div
                style={{
                  marginBottom: "50px",
                  fontWeight: "500",
                  fontSize: "20px",
                }}
              >
                {b2?.C2Description}
              </div>
            </Grid>
            <Grid item lg={2} md={5} xs={5}>
              <Grid
                className={classes.communityText}
                style={{
                  backgroundColor: "#221857",
                }}
              >
                <p className={classes.cardText} style={{ color: "white" }}>
                  03
                </p>
                <p className={classes.cardText} style={{ color: "white" }}>
                  {b2?.C3Heading}
                </p>
                {/* <div style={{marginLeft: '99px', marginTop: '48px'}}>
    <img src={svg3}/>
    </div> */}
              </Grid>
              <div
                style={{
                  marginBottom: "50px",
                  fontWeight: "500",
                  fontSize: "20px",
                }}
              >
                {b2?.C3Description}
              </div>
            </Grid>
            <Grid item lg={2} md={5} xs={5}>
              <Grid
                className={classes.communityText}
                style={{
                  backgroundColor: "#002856",
                }}
              >
                <p className={classes.cardText} style={{ color: "#fff" }}>
                  04
                </p>
                <p className={classes.cardText} style={{ color: "#fff" }}>
                  {b2?.C4Heading}
                </p>
                {/* <div style={{marginLeft: '112px', marginTop: '6px'}}>
    <img src={svg4}/>
    </div> */}
              </Grid>
              <div
                style={{
                  marginBottom: "50px",
                  fontWeight: "500",
                  fontSize: "20px",
                }}
              >
                {b2?.C4Description}
              </div>
            </Grid>
          </Grid>
        </Grid>
        <div container className={classes.Internships}>
          <Grid
            container
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
              "@media only screen and (max-width: 480px)": {
                disply: "flex",
                flexDirection: "column",
              },
            }}
          >
            <Grid item lg={4} md={12} xs={12} className={classes.ourTeam1}>
              <div
                className={classes.heading}
                style={{
                  fontSize: "50px",
                }}
              >
                <p> Technology Driven Growth</p>
              </div>
              <p className={classes.mentorshipText}>
                Supporting the aim to create an efficient ecosystem, we are
                backing technology startups driven on latest tech and trends.
                Our Focus Industry are
              </p>
            </Grid>

            <Grid
              item
              lg={4}
              md={12}
              xs={12}
              style={{
                marginTop: "20px",
                marginBottom: "50px",
                "@media only screen and (max-width: 480px)": {
                  marginBottom: "50px",
                },
              }}
            >
              <div className={classes.mentorshipList}>
                <li style={{ marginBottom: "10px" }}>Guided Mode On</li>
                <li style={{ marginBottom: "10px" }}>Founders To help you</li>
                <li style={{ marginBottom: "10px" }}>
                  {" "}
                  Make your startup work for you
                </li>
                <li style={{ marginBottom: "10px" }}>
                  Get clarity of major issues surfacing
                </li>
              </div>
            </Grid>
          </Grid>
        </div>

        <div style={{ background: "#fff", marginBottom: "20px" }}>
          <Insights />
        </div>
      </div>

      <div className={classes.body2}>
        <div className={classes.container}>
          <div className={classes.heading}>Numbers That Keep Growing</div>
          <Grid container spacing={3}>
            <Grid item lg={4} xs={12}>
              <div className={classes.cardHeading}>
                <CountUp
                  end={50000}
                  enableScrollSpy={true}
                  scrollSpyOnce={false}
                  scrollSpyDelay={0}
                  duration={3}
                />
                +
                {/*<CountUp end={b3?.num1} enableScrollSpy={true} scrollSpyOnce={true} scrollSpyDelay={3000} duration={5}/>+ */}
                {/* {b3?.num1} */}
              </div>
              <div className={classes.cardsubHeading}>{b3?.title1}</div>

              <Grid style={{ fontWeight: "bold" }}>{b3?.desc1}</Grid>
            </Grid>
            <Grid item lg={4} xs={12}>
              <div className={classes.cardHeading}>
                <CountUp
                  end={170}
                  enableScrollSpy={true}
                  scrollSpyOnce={false}
                  scrollSpyDelay={0}
                  duration={4}
                />
                +{/* {b3?.num2} */}
              </div>
              <div className={classes.cardsubHeading}>{b3?.title2}</div>
              <Grid style={{ fontWeight: "bold" }}>{b3?.desc2}</Grid>
            </Grid>
            <Grid item lg={4} xs={12}>
              <div className={classes.cardHeading}>
                <CountUp
                  end={46}
                  enableScrollSpy={true}
                  scrollSpyOnce={false}
                  scrollSpyDelay={0}
                  duration={5}
                />
                +{/* {b3?.num3} */}
              </div>
              <div className={classes.cardsubHeading}>{b3?.title3}</div>
              <Grid style={{ fontWeight: "bold" }}>{b3?.desc3}</Grid>
            </Grid>
          </Grid>
        </div>
      </div>

      <div container style={{ backgroundColor: "#221857" }}>
        <Grid
          container
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
            "@media only screen and (max-width: 480px)": {
              disply: "flex",
              flexDirection: "column",
            },
          }}
        >
          <Grid item lg={4} md={12} xs={12} className={classes.ourTeam1}>
            <img src={wp} className={classes.ourTeamImage} />
          </Grid>
          <Grid item lg={4} md={12} xs={12} className={classes.ourTeam2}>
            <div
              className={classes.heading}
              style={{ textAlign: "left", marginLeft: "10px" }}
            >
              Our Team at Markoknow
            </div>
            <div
              style={{
                color: "white",
                fontSize: "20px",
                marginLeft: "10px",
              }}
            >
              We are a group of enthusiastic people who envision a future of
              growth. We are leading the stepping stone for innovative startup
              building that could map us as a world leader in innovation and
              growth. With an aligned vision, we are building ideas and leading
              them towards milestone achievements.
            </div>
          </Grid>
        </Grid>
      </div>

      <Spotlight />

      <div
        container
        style={{
          backgroundColor: "#D2EFF1",
          paddingTop: "50px",
          paddingBottom: "100px",
        }}
      >
        <Grid container>
          <div className={classes.heading2} style={{ marginBottom: "50px" }}>
            Our Sessions At
          </div>
        </Grid>
        <Grid className={classes.carouselCompanies}>
          <Grid style={{ paddingTop: "20px" }}>
            {newlist.length && (
              <Carousel
                slides={newlist.map((item, index) => (
                  <>
                    <div className={classes.insideCarosel}>
                      <img
                        src={newlist[index]}
                        style={{
                          width: "200px",
                          height: "140px",
                          margin: "auto",
                          display: "block",
                          borderRadius: "10px",
                        }}
                      />
                    </div>
                  </>
                ))}
                autoplay={true}
                interval={5000}
                animation={"slide"}
              />
            )}
          </Grid>
        </Grid>
      </div>

      <Grid
        container
        style={{ backgroundColor: "#221857", paddingTop: "100px" }}
      >
        <div
          className={classes.heading}
          style={{
            marginBottom: "50px",
            marginLeft: "0px",
            textAlign: "center",
          }}
        >
          Frequently Asked Questions ( FAQ )
        </div>
        <Grid container className={classes.faq}>
          <Grid container className={classes.roads}>
            <Button
              style={{
                paddingTop: "10px",
                width: "100%",
                justifyContent: "flex-start",
              }}
              onClick={() => {
                setRoad1(!road1);
              }}
            >
              <Grid style={{ display: "flex", flexDirection: "column" }}>
                <Grid
                  container
                  style={{ display: "flex", flexDirection: "row" }}
                >
                  <Grid item>
                    <Button className={classes.btnRoadmap}>1.</Button>
                  </Grid>
                  <Grid item style={{ marginLeft: "10px" }}>
                    <div className={classes.textRoadmap}>
                      How can I start a Startup with Markoknow Community
                    </div>
                  </Grid>
                </Grid>
                <Collapse
                  in={road1}
                  timeout={{ enter: 500, exit: 500 }}
                  unmountOnExit
                >
                  <Grid className={classes.roadsText}>
                    As a Markoknow community member, you get access to practical
                    hands-on lessons from founders, Mentorship and Guidance from
                    Peer Founders and Investors and Funding routes are opened to
                    you for further paths. All this helps you kickstart and grow
                    your startup.
                  </Grid>
                </Collapse>
              </Grid>
            </Button>
          </Grid>
          <Grid container className={classes.roads}>
            <Button
              style={{
                paddingTop: "10px",
                width: "100%",
                justifyContent: "flex-start",
              }}
              onClick={() => {
                setRoad2(!road2);
              }}
            >
              <Grid style={{ display: "flex", flexDirection: "column" }}>
                <Grid
                  container
                  style={{ display: "flex", flexDirection: "row" }}
                >
                  <Grid item>
                    <div className={classes.btnRoadmap}>2.</div>
                  </Grid>
                  <Grid item style={{ marginLeft: "10px" }}>
                    <div className={classes.textRoadmap}>
                      How can I become a community member at Markoknow
                    </div>
                  </Grid>
                </Grid>
                <Collapse
                  in={road2}
                  timeout={{ enter: 500, exit: 500 }}
                  unmountOnExit
                >
                  <Grid className={classes.roadsText}>
                    By signing up to the Markoknow Login/Signup button, you get
                    added to the Markoknow community. You can exclusively join
                    the founder’s community from the joining mail. From the date
                    of signup, you start receiving the benefits of being a Part
                    of Markoknow Community.
                  </Grid>
                </Collapse>
              </Grid>
            </Button>
          </Grid>
          <Grid container className={classes.roads}>
            <Button
              style={{
                paddingTop: "10px",
                width: "100%",
                justifyContent: "flex-start",
              }}
              onClick={() => {
                setRoad3(!road3);
              }}
            >
              <Grid style={{ display: "flex", flexDirection: "column" }}>
                <Grid
                  container
                  style={{ display: "flex", flexDirection: "row" }}
                >
                  <Grid item>
                    <div className={classes.btnRoadmap}>3.</div>
                  </Grid>
                  <Grid item style={{ marginLeft: "10px" }}>
                    <div className={classes.textRoadmap}>
                      How can a community member benefit from being a part of
                      Markoknow
                    </div>
                  </Grid>
                </Grid>
                <Collapse
                  in={road3}
                  timeout={{ enter: 500, exit: 500 }}
                  unmountOnExit
                >
                  <Grid className={classes.roadsText}>
                    {" "}
                    Markoknow Community members get a lot of boost in their
                    knowledge, guidance and networking for their Startup Journey
                  </Grid>
                </Collapse>
              </Grid>
            </Button>
          </Grid>
          <Grid container className={classes.roads}>
            <Button
              style={{
                paddingTop: "10px",
                width: "100%",
                justifyContent: "flex-start",
              }}
              onClick={() => {
                setRoad4(!road4);
              }}
            >
              <Grid style={{ display: "flex", flexDirection: "column" }}>
                <Grid
                  container
                  style={{ display: "flex", flexDirection: "row" }}
                >
                  <Grid item>
                    <div className={classes.btnRoadmap}>4.</div>
                  </Grid>
                  <Grid item style={{ marginLeft: "10px" }}>
                    <div className={classes.textRoadmap}>
                      Does Markoknow helps in raising funds
                    </div>
                  </Grid>
                </Grid>
                <Collapse
                  in={road4}
                  timeout={{ enter: 500, exit: 500 }}
                  unmountOnExit
                >
                  <Grid className={classes.roadsText}>
                    {" "}
                    Markoknow has its funding section form where we take
                    requests from startups that want to showcase their deck and
                    raise funds. Once our team selects the pitch for further
                    rounds, we help selected startups raise funds in our
                    network.
                  </Grid>
                </Collapse>
              </Grid>
            </Button>
          </Grid>
          <Grid container className={classes.roads}>
            <Button
              style={{
                paddingTop: "10px",
                width: "100%",
                justifyContent: "flex-start",
              }}
              onClick={() => {
                setRoad5(!road5);
              }}
            >
              <Grid style={{ display: "flex", flexDirection: "column" }}>
                <Grid
                  container
                  style={{ display: "flex", flexDirection: "row" }}
                >
                  <Grid item>
                    <div className={classes.btnRoadmap}>5.</div>
                  </Grid>
                  <Grid item style={{ marginLeft: "10px" }}>
                    <div className={classes.textRoadmap}>
                      Does Markoknow helps in mentorship
                    </div>
                  </Grid>
                </Grid>
                <Collapse
                  in={road5}
                  timeout={{ enter: 500, exit: 500 }}
                  unmountOnExit
                >
                  <Grid className={classes.roadsText}>
                    {" "}
                    Markoknow has a lot of sessions, resources and community
                    activities that facilitate free mentorship and strong
                    foundation. Further mentorship is chargeable as per the
                    support that is needed by the startup specifically and how
                    much of the time and efforts are involved to mentor the
                    mentee.
                  </Grid>
                </Collapse>
              </Grid>
            </Button>
          </Grid>
        </Grid>
      </Grid>
      {/* <Question /> */}
      <Box sx={{ background: "#fff", padding: 2 }}>
        <Form
          open={true}
          onClose={""}
          category={"Contact - Main Page"}
          type=""
        />
      </Box>
      <Footer />
    </>
  );
}

export default NewHome;
