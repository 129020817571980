import { Button, Box, Typography, Grid } from "@mui/material";
import FocusAreas from "../FocusAreas";
import pic1 from "../../images/D3.png";
import pic2 from "../../images/D4.png";
import { useMediaQuery, useTheme } from "@mui/material";
import ReportsAccess from "../Reports";
import OngoingProjects from "../OngoingProjects";
import Footer from "../About/Footer";
import { useState } from "react";
import Form from "../Form";

export default function ResearchSection() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [openPartner, setOpenPartner] = useState(false);
  const [openConnect, setOpenConnect] = useState(false);

  const handlePartnerOpen = () => setOpenPartner(true);
  const handlePartnerClose = () => setOpenPartner(false);

  const handleConnectOpen = () => setOpenConnect(true);
  const handleConnectClose = () => setOpenConnect(false);

  const handleClose = () => {
    if (openPartner) handlePartnerClose();
    if (openConnect) handleConnectClose();
  };

  return (
    <>
      <Box sx={{ width: "100%", background: "#fff" }}>
        <Box
          sx={{
            width: "97%",
            mx: "auto",
            px: 2,
            py: { xs: 6, md: 8 },
            background: "#fff",
            height: "100%",
          }}
        >
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} md={6}>
              <Box>
                <Box
                  component="span"
                  sx={{
                    backgroundColor: "#6B46C1",
                    color: "white",
                    px: 2,
                    py: 0.5,
                    borderRadius: 1,
                    display: "inline-block",
                  }}
                >
                  Research
                </Box>
                <Typography
                  variant="h3"
                  sx={{
                    color: "#1E1B4B",
                    fontWeight: "bold",
                    mt: 2,
                    lineHeight: 1.2,
                  }}
                >
                  Markoknow Research & Development (R&D)
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ color: "gray", maxWidth: "500px", mt: 2 }}
                >
                  Enhancing the Innovation through major efforts in Technology,
                  Data, Robotics and AI/ML
                </Typography>
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2, mt: 3 }}>
                  <Button
                    sx={{
                      backgroundColor: "#1E1B4B",
                      color: "white",
                      px: 4,
                      ":hover": { backgroundColor: "#2D2A6B" },
                    }}
                    onClick={handlePartnerOpen}
                  >
                    Partner Us
                  </Button>
                  <Button
                    variant="outlined"
                    sx={{
                      borderColor: "#1E1B4B",
                      color: "#1E1B4B",
                      px: 4,
                      ":hover": { backgroundColor: "#1E1B4B", color: "white" },
                    }}
                    onClick={handleConnectOpen}
                  >
                    Connect with Us
                  </Button>
                  {openPartner && (
                    <Form
                      open={openPartner}
                      onClose={handleClose}
                      category="R&D Partner Us"
                    />
                  )}

                  {openConnect && (
                    <Form
                      open={openConnect}
                      onClose={handleClose}
                      category="R&D Contact Us"
                    />
                  )}
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} md={6} container justifyContent="center">
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "nowrap", // Prevents stacking on mobile
                  gap: "10px", // Adjusts spacing between images
                  alignItems: "flex-start", // Align images properly
                }}
              >
                {[pic1, pic2].map((pic, index) => (
                  <Box
                    key={index}
                    sx={{
                      position: "relative",
                      width: isMobile ? 180 : 250, // Reduce width on mobile
                      height: isMobile ? 300 : 400, // Reduce height on mobile
                      borderRadius: 2,
                      overflow: "hidden",
                      marginTop:
                        index === 1 ? (isMobile ? "-10px" : "-20px") : "0px", // Adjust offset slightly on mobile
                    }}
                  >
                    <img
                      src={pic}
                      alt={`Research image ${index + 1}`}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  </Box>
                ))}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <FocusAreas />
      <ReportsAccess />
      <OngoingProjects />
      <Footer />
    </>
  );
}
