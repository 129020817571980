import "../../stylesheets/carrerpage.css";
import { Typography, Button } from "@mui/material";
import { BsArrowRight } from "react-icons/bs";
import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Fade from "@mui/material/Fade";
import Loader from "./Loader.jsx";
import { useMediaQuery } from "@mui/material";
import axios from "axios";
import { POST_CAREER } from "../../api/career";
import { validateEmail } from "../auth/validateEmail";
import { useHistory } from "react-router-dom";
import OpenPositions from "../OpenPostions/index.jsx";

const CareerPage = () => {
  const navigate = useHistory();
  const currencies = [
    {
      value: "Marketing",
      label: "Marketing",
    },
    {
      value: "Tech",
      label: "Tech",
    },
  ];
  const opportunities = [
    {
      value: "Full Time",
      label: "Full Time",
    },
    {
      value: "Part Time",
      label: "Part Time",
    },
    {
      value: "Freelance",
      label: "Freelance",
    },
    {
      value: "Internship",
      label: "Internship",
    },
  ];

  const [clicked, setClicked] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [fName, setFName] = React.useState("");
  const [lName, setLName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [wNumber, setWnumber] = React.useState("");
  const [domain, setDomain] = React.useState("");
  const [oType, setOtype] = React.useState("");
  const [source, setSource] = React.useState("");
  const [college, setCollege] = React.useState("");
  const [answer, setAnswer] = React.useState("");
  const [compensation, setCompensation] = React.useState("");
  const [resume, setResume] = React.useState(null);
  const [emailError, setEmailError] = React.useState("");
  const [response, setResponse] = React.useState("");
  const [numberError, setNumberError] = React.useState("");

  const isMobile = useMediaQuery("(max-width:838px)");

  const validate = () => {
    if (fName.length <= 0 && lName.length <= 0) return false;
    else if (wNumber.length !== 10) return false;
    else if (!validateEmail(email)) return false;
    else if (!resume) return false;
    else return true;
  };

  const handleApply = () => {
    if (validate()) {
      const data = new FormData();
      data.append("fName", fName);
      data.append("lName", lName);
      data.append("email", email);
      data.append("wNumber", wNumber);
      data.append("domain", domain);
      data.append("oType", oType);
      data.append("source", source);
      data.append("college", college);
      data.append("answer", answer);
      data.append("compensation", compensation);
      data.append("resume", resume);
      setLoading(true);
      axios
        .post(POST_CAREER, data)
        .then((response) => {
          setLoading(false);
          setClicked(true);
        })
        .catch((err) => {});
    } else {
      setResponse("Entered Wrong Data");
      alert(response);
    }
  };
  return (
    <>
      <div
        className="career-main-page"
        style={{
          flexDirection: isMobile && "column",
          background: "#ffff",
        }}
      >
        {!isMobile && (
          <Fade in={true} timeout={2000}>
            <div
              className="career-page1"
              style={{
                width: isMobile && "100%",
              }}
            >
              <div
                style={{
                  width: "50%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant={!isMobile ? "h2" : "h4"}
                  style={{
                    color: "#000",
                    fontWeight: "bold",
                    fontFamily: "Poppins",
                    marginBottom: "10px",
                  }}
                >
                  Career At Markoknow
                </Typography>
                <Typography
                  variant="subtitle1"
                  style={{
                    color: "#000",
                    fontFamily: "Poppins",
                    marginBottom: "10px",
                  }}
                >
                  The best asset an Organization can hold are its people who
                  build and pave the path to Glory
                </Typography>
                <Button
                  variant="contained"
                  onClick={(e) => {
                    clicked && navigate("/");
                  }}
                  style={{
                    justifySelf: "center",
                    background: "#6A58C6",
                    width: "100%",
                    borderRadius: "10px",
                    fontFamily: "Poppins",
                    fontSize: "20px",
                  }}
                >
                  {clicked ? "Home" : "Apply Now"} &nbsp;{" "}
                  {!clicked && (
                    <BsArrowRight fontSize="20px" fontWeight="bold" />
                  )}{" "}
                </Button>
              </div>
            </div>
          </Fade>
        )}
        <Fade in={true} timeout={3000}>
          <div
            className="career-page2"
            style={{
              width: isMobile && "100%",
              height: isMobile ? "100%" : "600px",
            }}
          >
            <Box
              component="form"
              sx={{
                "& .MuiTextField-root": { m: !isMobile ? 1 : 0.5 },
                margin: "2%",
                borderRadius: "10px",
                background: "#6A58C6",
                height: "105%",
                padding: "2% 10% 5% 10% ",
                display: clicked && "flex",
                justifyContent: clicked && "center",
                alignItems: clicked && "center",
                boxShadow: "2px 2px 5px #6A58C6",
              }}
              noValidate
              autoComplete="off"
            >
              {loading && <Loader />}
              {!loading && !clicked && (
                <>
                  <Typography
                    variant="h4"
                    style={{
                      textAlign: "center",
                      fontFamily: "Poppins",
                      fontWeight: "bold",
                      color: "#fff",
                      marginBottom: "40px",
                    }}
                  >
                    Career
                  </Typography>
                  <div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: !isMobile ? "row" : "column",
                        width: "100%",
                      }}
                    >
                      <TextField
                        required
                        id="outlined-password-input"
                        label="First Name"
                        InputLabelProps={{
                          style: {
                            color: "#000",
                            borderColor: "#000",
                            fontFamily: "Poppins",
                          },
                        }}
                        style={{
                          background: "#fff",
                          borderRadius: "10px",

                          borderColor: "#000",
                          width: !isMobile ? "50%" : "100%",
                        }}
                        onChange={(e) => setFName(e.target.value)}
                      />
                      <TextField
                        required
                        id="outlined-password-input"
                        label="Last Name"
                        InputLabelProps={{
                          style: {
                            color: "#000",
                            borderColor: "#000",
                            fontFamily: "Poppins",
                          },
                        }}
                        style={{
                          background: "#fff",
                          borderRadius: "10px",
                          borderColor: "#000",
                          width: !isMobile ? "50%" : "100%",
                        }}
                        onChange={(e) => setLName(e.target.value)}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: !isMobile ? "row" : "column",
                        width: "100%",
                      }}
                    >
                      <TextField
                        required
                        id="outlined-password-input"
                        label="Email"
                        type="email"
                        InputProps={{
                          style: {
                            color: "#000",
                            borderColor: "#000",
                            fontFamily: "Poppins",
                            fontSize: "20px",
                          },
                        }}
                        InputLabelProps={{
                          style: {
                            color: "#000",
                            borderColor: "#000",
                            fontFamily: "Poppins",
                          },
                        }}
                        FormHelperTextProps={{
                          style: { color: "red" },
                        }}
                        style={{
                          background: "#fff",
                          borderRadius: "10px",
                          borderColor: "#000",
                          width: !isMobile ? "50%" : "100%",
                        }}
                        onChange={(e) => {
                          if (
                            !validateEmail(e.target.value) &&
                            e.target.value.length > 0
                          )
                            setEmailError("Enter A Correct Email");
                          else setEmailError("");
                          setEmail(e.target.value);
                        }}
                        helperText={emailError}
                      />

                      <TextField
                        required
                        id="outlined-password-input"
                        label="Whatsapp Number"
                        InputProps={{
                          style: {
                            color: "#000",
                            borderColor: "#000",
                            fontFamily: "Poppins",
                            fontSize: "20px",
                          },
                        }}
                        InputLabelProps={{
                          style: {
                            color: "#000",
                            borderColor: "#000",
                            fontFamily: "Poppins",
                          },
                        }}
                        FormHelperTextProps={{
                          style: { color: "red" },
                        }}
                        style={{
                          background: "#fff",
                          borderRadius: "10px",
                          borderColor: "#000",
                          width: !isMobile ? "50%" : "100%",
                        }}
                        onChange={(e) => {
                          if (
                            e.target.value.length > 10 &&
                            e.target.value.length !== 0
                          )
                            setNumberError("Enter A Correct Number");
                          else setNumberError("");
                          setWnumber(e.target.value);
                        }}
                        helperText={numberError}
                      />
                    </div>
                    {/* {emailError && (<span style={{marginLeft:'10px' , color:'red'}}>{emailError}</span>)}
      {numberError && (<span style={{marginLeft:'10px' , color:'red'}}>{numberError}</span>)} */}

                    <div
                      style={{
                        display: "flex",
                        flexDirection: !isMobile ? "row" : "column",
                        width: "100%",
                      }}
                    >
                      <TextField
                        id="outlined-select-currency"
                        select
                        label="Select Your Domain"
                        InputLabelProps={{
                          style: {
                            color: "#000",
                            borderColor: "#000",
                            fontFamily: "Poppins",
                          },
                        }}
                        style={{
                          background: "#fff",
                          borderRadius: "5px",
                          borderColor: "#000",
                          width: !isMobile ? "50%" : "100%",
                        }}
                        onChange={(e) => setDomain(e.target.value)}
                      >
                        {currencies.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                      <TextField
                        id="outlined-select-currency"
                        select
                        label="Select Your Opportunity Type"
                        InputLabelProps={{
                          style: {
                            color: "#000",
                            borderColor: "#000",
                            fontFamily: "Poppins",
                          },
                        }}
                        style={{
                          background: "#fff",
                          borderRadius: "5px",
                          borderColor: "#000",
                          width: !isMobile ? "50%" : "100%",
                        }}
                        onChange={(e) => setOtype(e.target.value)}
                      >
                        {opportunities.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                      }}
                    >
                      <TextField
                        id="outlined-multiline-flexible"
                        label="How Did You Come To Know About Us ?"
                        multiline
                        maxRows={4}
                        InputLabelProps={{
                          style: {
                            color: "#000",
                            borderColor: "#000",
                            fontFamily: "Poppins",
                          },
                        }}
                        style={{
                          background: "#fff",
                          borderRadius: "10px",

                          borderColor: "#000",
                          width: !isMobile ? "98%" : "100%",
                        }}
                        onChange={(e) => setSource(e.target.value)}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: !isMobile ? "row" : "column",
                        width: "100%",
                      }}
                    >
                      <TextField
                        required
                        id="outlined-required"
                        label="Last Attended College"
                        InputLabelProps={{
                          style: {
                            color: "#000",
                            borderColor: "#000",
                            fontFamily: "Poppins",
                          },
                        }}
                        style={{
                          background: "#fff",
                          borderRadius: "10px",

                          borderColor: "#000",
                          width: !isMobile ? "98%" : "100%",
                        }}
                        onChange={(e) => setCollege(e.target.value)}
                      />
                      <TextField
                        id="outlined-required"
                        label="Expected Compensation"
                        InputLabelProps={{
                          style: {
                            color: "#000",
                            borderColor: "#000",
                            fontFamily: "Poppins",
                          },
                        }}
                        style={{
                          background: "#fff",
                          borderRadius: "10px",

                          borderColor: "#000",
                          width: !isMobile ? "98%" : "100%",
                        }}
                        onChange={(e) => setCompensation(e.target.value)}
                      />
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: !isMobile ? "row" : "column",
                        width: "100%",
                      }}
                    >
                      <TextField
                        id="outlined-multiline-flexible"
                        label="Why are you right fit for organisation ?"
                        multiline
                        maxRows={4}
                        InputLabelProps={{
                          style: {
                            color: "#000",
                            borderColor: "#000",
                            fontFamily: "Poppins",
                          },
                        }}
                        style={{
                          background: "#fff",
                          borderRadius: "10px",

                          borderColor: "#000",
                          width: !isMobile ? "98%" : "100%",
                        }}
                        onChange={(e) => setAnswer(e.target.value)}
                      />
                    </div>
                    <div
                      style={{
                        margin: "1%",
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        variant="contained"
                        component="label"
                        style={{
                          width: !isMobile ? "50%" : "100%",
                          background: "#000",
                          fontFamily: "Poppins",
                          marginBottom: "10px",
                        }}
                      >
                        Upload Resume
                        <input
                          type="file"
                          onChange={(e) => setResume(e.target.files[0])}
                          hidden
                        />
                      </Button>
                      {!isMobile && (
                        <Button
                          variant="contained"
                          component="label"
                          onClick={handleApply}
                          style={{
                            width: !isMobile ? "50%" : "100%",
                            background: "#000",
                            fontFamily: "Poppins",
                          }}
                        >
                          Submit
                        </Button>
                      )}
                    </div>
                  </div>
                </>
              )}
              {!loading && clicked && (
                <div
                  style={{
                    width: "70%",
                  }}
                >
                  <Typography
                    variant="h4"
                    style={{
                      color: "#fff",
                      textAlign: "center",
                      fontFamily: "Poppins",
                      fontWeight: "bold",
                    }}
                  >
                    Thank You
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    style={{
                      color: "#fff",
                      textAlign: "center",
                      fontFamily: "Poppins",
                      fontWeight: "bold",
                      wordWrap: "break-word",
                    }}
                  >
                    For showing your interest to join Markoknow. <br /> You can
                    re-apply to update any progress in <br /> your career by
                    filling the same form after 6 <br /> month. To reach out to
                    us, drop a mail at hr@markoknow.com
                  </Typography>
                </div>
              )}
            </Box>
          </div>
        </Fade>
        {isMobile && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "10px",
            }}
          >
            <Button
              variant="contained"
              onClick={() => {
                if (!clicked) handleApply();
                else navigate("/");
              }}
              style={{
                justifySelf: "center",
                background: "#6A58C6",
                width: "70%",
                borderRadius: "10px",
                fontFamily: "Poppins",
                fontSize: "20px",
              }}
            >
              {clicked ? "Home" : "Apply Now"} &nbsp;{" "}
              {!clicked && <BsArrowRight fontSize="20px" fontWeight="bold" />}{" "}
            </Button>
          </div>
        )}
      </div>
      <OpenPositions />
    </>
  );
};
export default CareerPage;
