import React, { useEffect, useState } from "react";
import { isBrowser } from "react-device-detect";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom";
import "../../stylesheets/Navbar.css";
import "../../stylesheets/navbarTop.css";
import logomark from "../../images/logomark1.jpeg";
import axios from "axios";
import HomeIcon from "@mui/icons-material/Home";
/*-------------new imports-------------*/
import { Drawer, Hidden, Grid } from "@material-ui/core";

import NotificationBar from "./NotificationBar";
import MenuIcon from "@material-ui/icons/Menu";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",

    color: "black",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  btn: {
    margin: "5px",
    borderRadius: "30px",
    color: "black",

    "&:hover": {
      backgroundColor: "#221857",
      color: "black",
      borderColor: "#9A4CDF",
    },
  },
  title: {
    color: "black",
    flexGrow: 1,
    //backgroundColor: 'yellow',
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  titleGrid: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    //flexDirection: 'column',
    //backgroundColor:'red',
  },
  drawerButtonGrid: {
    //backgroundColor: "red",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  drawerPaper: {
    backgroundColor: "#000",
  },
  drawerIcon: {
    marginTop: "auto",
    marginBottom: "auto",
    textAlign: "right",
  },
  container: {
    backgroundColor: "#6A58c6",
    //backgroundColor: "inherit",
    height: "100px",
  },
  announcement: {
    backgroundColor: "#9A4CDF",
    padding: "5px",
    fontWeight: "bold",
    borderRadius: "5px",
  },
}));

const DOMAIN = process.env.REACT_APP_DOMAIN;

function NavbarTop({ isAuth, setIsAuth }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isOpen, setOpen] = React.useState(false);

  const [MentorLink, setMentorLink] = useState("");

  useEffect(() => {
    axios
      .get(`${DOMAIN}/updatelinks/mentorship/62fb7e478f4ca450e8eef76e`)
      .then((resp) => {
        setMentorLink(resp.data.link);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const open = Boolean(anchorEl);
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logoutHandler = () => {
    setAnchorEl(null);
    setIsAuth(false);
    window.localStorage.clear();
  };
  const handleOnClick = () => {
    setOpen(!isOpen);
  };

  const drawer = (
    <div
      style={{
        height: "auto",
        // border: "1px solid red",
        width: "110%",
        marginLeft: "-1%",
        "&:hover": { backgroundColor: "inherit" },
        "&:active": {
          backgroundColor: "inherit",
          borderColor: "inherit",
          boxShadow: "inherit",
        },
      }}
    >
      <IconButton className={classes.btn} aria-label="members">
        <Link to={"/"}>
          <HomeIcon sx={{ color: "white" }} />
        </Link>
      </IconButton>
      <NavLink to="/member" className="navbar_item">
        <IconButton className={classes.btn} aria-label="members">
          <h6 className="navbar_member">MEMBERSHIP</h6>
        </IconButton>
      </NavLink>
      {/* <IconButton className="icons" aria-label="members">
        <NavLink to={`/`} className="navbar_item">
          <h6 className="navbar_member">
            HOME<div id="border_bottom1"></div>
          </h6>
        </NavLink>
      </IconButton> */}
      {/* <NavLink to={`/community`} className="navbar_item">
        <IconButton className={classes.btn} aria-label="members">
          <h6 className="navbar_member">COMMUNITY</h6>
        </IconButton>
      </NavLink> */}
      <NavLink to="/partnerwithus" className="navbar_item">
        <IconButton className={classes.btn} aria-label="members">
          <h6 className="navbar_member">PARTNER</h6>
        </IconButton>
      </NavLink>
      <NavLink to={`/about`} className="navbar_item">
        <IconButton className={classes.btn} aria-label="members">
          <h6 className="navbar_member">ABOUT US</h6>
        </IconButton>
      </NavLink>
      <a
        href={`https://virtualstartupcampus.com`}
        target="_blank"
        className="navbar_item"
      >
        <IconButton className={classes.btn} aria-label="members">
          <h6 className="navbar_member">MARKOKNOW CAMPUS</h6>
        </IconButton>
      </a>
      <NavLink to={`/research`} className="navbar_item">
        <IconButton className={classes.btn} aria-label="members">
          <h6 className="navbar_member">MARKOKNOW R&D</h6>
        </IconButton>
      </NavLink>
      {/* 
      <NavLink to="/founders" className="navbar_item">
        <IconButton className={classes.btn} aria-label="members">
          <h6 className="navbar_member">FOUNDERS</h6> */}
      {/* <div className={classes.announcement}>Book 1 Free Mentorship</div> */}
      {/* </IconButton>
      </NavLink> */}
      {/* <IconButton className="icons" aria-label="members">
        <NavLink to="/learnnow" className="navbar_item">
          <h6 className="navbar_member">
            LEARN<div id="border_bottom1"></div>
          </h6>
        </NavLink>
      </IconButton> */}

      {/* <NavLink to="/partnership" className="navbar_item"> */}
      {/* <NavLink to="/" className="navbar_item">
        <IconButton className={classes.btn} aria-label="members">
          <h6 className="navbar_member">STARTUP CAMPUS</h6>
        </IconButton>
      </NavLink> */}

      <IconButton
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
        color="inherit"
        className="account_icon icons"
      >
        {isBrowser && !isAuth && (
          <button className="loginBtn">
            <b>LOGIN</b>
          </button>
        )}
        {isBrowser && isAuth && (
          <>
            <button className="loginBtn">
              {window.localStorage.getItem("name").substring(0, 9)}
            </button>
          </>
        )}
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
          marginLeft: "50px",
        }}
        open={open}
        onClose={handleClose}
      >
        {isAuth ? (
          <div>
            <NavLink
              to={"/profile/" + window.localStorage.getItem("id")}
              exact
              style={{ textDecoration: "none", color: "black" }}
              className="nav-link"
            >
              <MenuItem onClick={handleClose}>
                {window.localStorage.getItem("name")}
              </MenuItem>
            </NavLink>
            <NavLink
              to="/contact"
              exact
              style={{ textDecoration: "none", color: "black" }}
              className="nav-link"
            >
              <MenuItem onClick={handleClose}>CONTACT US</MenuItem>
            </NavLink>
            <NavLink
              to="/feedback"
              exact
              style={{ textDecoration: "none", color: "black" }}
              className="nav-link"
            >
              <MenuItem onClick={handleClose}>FEEDBACK</MenuItem>
            </NavLink>
            <MenuItem onClick={logoutHandler}>LOGOUT</MenuItem>
          </div>
        ) : (
          <div>
            <NavLink
              to="/signup"
              exact
              style={{ textDecoration: "none", color: "black" }}
              className="nav-link"
            >
              <MenuItem onClick={logoutHandler}>SIGNUP</MenuItem>
            </NavLink>
            <NavLink
              to="/login"
              exact
              style={{
                textDecoration: "none",
                color: "black",
                backgroundColor: "yellow",
              }}
              className="nav-link"
            >
              <MenuItem onClick={logoutHandler}>LOGIN</MenuItem>
            </NavLink>
            <NavLink
              to="/feedback"
              exact
              style={{
                textDecoration: "none",
                color: "black",
                backgroundColor: "yellow",
              }}
              className="nav-link"
            >
              <MenuItem onClick={logoutHandler}>FEEDBACK</MenuItem>
            </NavLink>
          </div>
        )}
      </Menu>
    </div>
  );

  return (
    <>
      <div className={classes.root}>
        <AppBar className={classes.container}>
          <NotificationBar />
          <Toolbar>
            <Grid container>
              <Grid
                item
                lg={4}
                md={8}
                sm={9}
                xs={10}
                className={classes.titleGrid}
                //style={{ backgroundColor: "red" }}
              >
                <Link to="/">
                  <Typography variant="h6" className={classes.title}>
                    <div
                      onClick={() => {
                        <NavLink to="/" />;
                      }}
                      style={{
                        textDecoration: "none",
                        color: "white",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {/* <img className="image_logo" src={logomark} alt="Logo" /> */}
                      <h1>
                        Markoknow<sup style={{ fontSize: "20px" }}>™</sup>
                      </h1>
                    </div>
                  </Typography>
                </Link>
              </Grid>
              <Grid
                item
                lg={8}
                md={4}
                sm={3}
                xs={2}
                className={classes.drawerButtonGrid}
                //style={{ backgroundColor: "red" }}
              >
                <Hidden only={["md", "sm", "xs"]}>{drawer}</Hidden>
                <Hidden only={["xl", "lg"]}>
                  <div className={classes.drawerIcon}>
                    <IconButton
                      onClick={handleOnClick}
                      color="inherit"
                      aria-label="open drawer"
                    >
                      <MenuIcon fontSize="large" style={{ margin: "auto" }} />
                    </IconButton>
                  </div>
                  <Drawer
                    variant="temporary"
                    open={isOpen}
                    anchor="top"
                    onClose={handleOnClick}
                    classes={{
                      paper: classes.drawerPaper,
                    }}
                    ModalProps={{
                      keepMounted: true, // Better open performance on mobile.
                    }}
                  >
                    {drawer}
                  </Drawer>
                </Hidden>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </div>
    </>
  );
}

export default NavbarTop;
