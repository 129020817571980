import React from "react";
import { Typography, Container, Grid, Box, Paper } from "@mui/material";
import Kaushiki from '../../../images/kaushiki.png';

export default function VisionMarkoknow() {
  return (
    <div
      style={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}
    >
      {/* Main Content */}
      <Container style={{ flex: 1, padding: "40px 20px" }}>
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12} lg={4}>
            <Typography variant="h3" fontWeight="bold">
              At Markoknow, <br /> Our Vision & <br /> Mission
            </Typography>
          </Grid>
          <Grid item xs={12} lg={4}>
            <Paper
              style={{
                backgroundColor: "#2512E1",
                color: "white",
                padding: "26px",
                borderRadius: "16px",
              }}
            >
              <Typography variant="h4" fontWeight="bold" gutterBottom>
                Vision
              </Typography>
              <Box
                style={{
                  width: "48px",
                  height: "4px",
                  backgroundColor: "#FFC107",
                  marginBottom: "8px",
                }}
              ></Box>
              <Typography>
                To be a catalyst for innovation, growth, and
                transformation—empowering individuals and businesses to lead
                with cutting-edge trends and create a future of limitless
                possibilities.
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} lg={4}>
            <Paper
              style={{
                backgroundColor: "#2512E1",
                color: "white",
                padding: "24px",
                borderRadius: "16px",
              }}
            >
              <Typography variant="h4" fontWeight="bold" gutterBottom>
                Mission
              </Typography>
              <Box
                style={{
                  width: "48px",
                  height: "4px",
                  backgroundColor: "#FFC107",
                  marginBottom: "8px",
                }}
              ></Box>
              <Typography>
                Through innovation, continuous learning, and the latest industry
                trends, we equip our people and businesses with the tools,
                strategies, and drive progress in their industries.
              </Typography>
            </Paper>
          </Grid>
        </Grid>

        {/* Quote Section */}
        <Grid
          container
          spacing={4}
          alignItems="center"
          style={{ marginTop: "40px" }}
        >
          <Grid item xs={12} lg={8}>
            <Typography variant="h4" fontWeight="bold">
              "For Us to be <span style={{ color: "#2512E1" }}>Leaders</span>,
              First we need to be
              <span style={{ color: "#2512E1" }}>
                {" "}
                Better Innovators, Better Executors, Better Collaborators
              </span>
              "
            </Typography>
            <Box style={{ marginTop: "16px" }}>
              <Typography variant="h6" fontWeight="bold">
                Kaushiki Kesarwani
              </Typography>
              <Typography color="textSecondary">CEO, Markoknow</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} lg={4}>
            <Box
              style={{ position: "relative", height: "400px", width: "100%" }}
            >
              <Box
                component="img"
                src={Kaushiki}
                alt="CEO Portrait"
                style={{
                  width: "100%",
                  height: "400px",
                  objectFit: "cover",
                  borderRadius: "8px",
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
