import React, { useEffect, useState } from "react";
import "./App.css";
import { Switch, Route, useHistory } from "react-router-dom";
import ReactGA from "react-ga";

//components
import Signup from "./components/auth/Signup";
import Login from "./components/auth/login";
import Profile from "./components/auth/profile";
import MembershipForm from "./components/MembershipForm";
import NavBar from "./components/NavBar/NavBar";
import Home from "./components/Dashboard/Home.jsx";
import EmailVerification from "./components/email/EmailVerification";
import EmailVerSignup from "./components/email/EmailVerSignup";
import EventForm from "./components/EventForm";
import ForgetPass from "./components/forgotPassword/ForgotPass";
import ResetPassword from "./components/forgotPassword/ResetPassword";
import PartnerShip from "./components/partnership/partnerShip";
import OrganiseForm from "./components/partnership/organiseForm";
import SponsorForm from "./components/partnership/sponsorForm";
import EmailTestimonial from "./components/email/EmailTestimonial";
import Testimonials from "./components/Testimonials/TestimonialDashboard";
import Subscription from "./components/subs/Subscription";
import CertificateGenerator from "./components/certificateGen/generator";
import UpcomingEvent from "./components/upcoming_event/upcoming_event";
import UserProfile from "./components/user_profile/user_profile";
import ChatHome from "./components/ChatSection/Pages/ChatHome";
import ChatStart from "./components/ChatSection/Pages/ChatStart";
import SamplePage from "./sample";
import Skillselection from "./components/Skill-Selection/Pages/Home";
import LearnNow from "./components/Learn-Now/Pages/Home";
import Membership from "./components/Member";
import StartupNow from "./components/StartupNow";
import Contact from "./components/Contact";
import WillComeSoon from "../src/CommingSoon";
import PartnerWithUs from "./components/Partner";
import EntreprenuerEvent from "./components/EntrepreneurEvents";
import Feedback from "./components/Feedback/Feedback";
import GetCerti from "./components/Questionare/Questionaire";
import StartupProblem from "./components/StartupProblem";
import Compatibility from "./components/Compatibility";
import Learners from "./components/Learners";
import NewHome from "./components/newHome/NewHome";
import LearnerLanding from "./components/LearnerLanding";
import VirtualCampus from "./components/VirtualCampus/VirtualCampus";
import PrivacyPolicy from "./components/PrivacyPolicy";
import TermsConditions from "./components/TermsConditions";
import AboutUs from "./components/AboutUs/index";
import PaymentCheckout from "./components/PaymentCheckout/PaymentCheckout";
import PaymentSuccess from "./components/PaymentCheckout/PaymentSuccess";
import RefundPolicy from "./components/RefundPolicy/index";
import PopupChat from "./components/PopupChat";
import Question from "./components/Question";
import ResearchSection from "./components/Research/index.js";
import Career from "./components/Career/CareerPage.jsx";

function App() {
  const [isAuth, setIsAuth] = useState(
    window.localStorage.getItem("isAuth") || false
  );
  const history = useHistory();

  useEffect(() => {
    ReactGA.initialize("UA-211886888-1");
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <div className="App">
      <NavBar isAuth={isAuth} setIsAuth={setIsAuth} />
      <Switch>
        <Route exact path="/" component={NewHome} />
        <Route exact path="/chatstart" component={ChatStart} />
        <Route exact path="/chathome" component={ChatHome} />
        <Route exact path="/chat" component={ChatStart} />
        <Route exact path="/sample" component={SamplePage} />
        <Route exact path="/willcomesoon" component={WillComeSoon} />
        <Route exact path="/feedback" component={Feedback} />
        <Route exact path="/entreprenuerevent" component={EntreprenuerEvent} />
        <Route exact path="/skillselection" component={Skillselection} />
        <Route
          exact
          path="/learnnow"
          component={() => <LearnNow isAuth={isAuth} setIsAuth={setIsAuth} />}
        />
        <Route exact path="/startupnow" component={StartupNow} />
        <Route
          exact
          path="/dashboard"
          component={() => <Home isAuth={isAuth} />}
        />
        {/* <Route
            exact
            path="/"
            component={() => <About isAuth={isAuth} setIsAuth={setIsAuth} />}
          /> */}
        <Route
          exact
          path="/oldHome"
          component={() => <LearnNow isAuth={isAuth} setIsAuth={setIsAuth} />}
        />
        <Route
          exact
          path="/member"
          component={() => <Membership isAuth={isAuth} />}
        />
        {/* <Route
            exact
            path="/fund"
            component={() => <Funding isAuth={isAuth} />}
          /> */}
        <Route
          exact
          path="/virtual_Campus"
          component={() => <VirtualCampus isAuth={isAuth} />}
        />
        <Route
          exact
          path="/getcertificate"
          component={() => <GetCerti isAuth={isAuth} setIsAuth={setIsAuth} />}
        />
        <Route
          exact
          path="/signup"
          component={() => <Signup isAuth={isAuth} setIsAuth={setIsAuth} />}
        />
        <Route
          exact
          path="/login"
          component={() => <Login isAuth={isAuth} setIsAuth={setIsAuth} />}
        />
        <Route
          exact
          path="/membership"
          component={() => <MembershipForm isAuth={isAuth} />}
        />
        <Route
          exact
          path="/partnership"
          component={() => <PartnerShip isAuth={isAuth} />}
        />
        <Route
          exact
          path="/partnership/organise"
          component={() => <OrganiseForm isAuth={isAuth} />}
        />
        <Route
          exact
          path="/partnership/sponser"
          component={() => <SponsorForm isAuth={isAuth} />}
        />
        <Route exact path="/partnerwithus" component={PartnerWithUs} />
        <Route exact path="/forgotpassword" component={ForgetPass} />
        <Route
          exact
          path="/bookevent"
          component={() => <EventForm isAuth={isAuth} setIsAuth={setIsAuth} />}
        />

        <Route
          exact
          path="/profile/:id"
          component={
            isAuth
              ? () => <Profile />
              : () => <Login history={history} setIsAuth={setIsAuth} />
          }
        />
        <Route
          exact
          path="/verify_email/:token"
          isAuth={isAuth}
          component={EmailVerification}
        />
        <Route
          exact
          path="/verify_signup_email/:token"
          isAuth={isAuth}
          component={EmailVerSignup}
        />
        <Route
          exact
          path="/verify_testimonial/:token"
          isAuth={isAuth}
          component={EmailTestimonial}
        />
        <Route exact path="/resetpassword/:token" component={ResetPassword} />
        <Route exact path="/testimonials" component={Testimonials} />
        <Route
          exact
          path="/subscription"
          component={() => <Subscription isAuth={isAuth} />}
        />
        <Route
          exact
          path="/community"
          component={() => <CertificateGenerator />}
        />
        <Route exact path="/upcoming_event" component={UpcomingEvent} />
        <Route exact path="/user_profile" component={UserProfile} />
        <Route exact path="/startupProblem" component={StartupProblem} />
        <Route exact path="/compatibility" component={Compatibility} />
        {/* <Route exact path="/founders" component={Founders} /> */}
        <Route exact path="/learners" component={Learners} />
        <Route exact path="/learnerLanding" component={LearnerLanding} />
        <Route exact path="/contact" component={Contact} />
        <Route exact path="/privacy_policy" component={PrivacyPolicy} />
        <Route exact path="/terms_conditions" component={TermsConditions} />
        <Route exact path="/about" component={AboutUs} />
        <Route
          exact
          path="/program/paymentcheckout/:id/:userid"
          component={PaymentCheckout}
        />
        <Route exact path="/success" component={PaymentSuccess} />
        <Route
          exact
          path="/refund&cancellationpolicy"
          component={RefundPolicy}
        />
        <Route exact path="/question" component={Question} />
        <Route exact path="/research" component={ResearchSection} />
        <Route exact path="/career" component={Career} />
      </Switch>
      <PopupChat />
    </div>
  );
}

export default App;
