import { useState } from "react";
import {
  TextField,
  Select,
  MenuItem,
  Button,
  Typography,
  Box,
  Container,
  Paper,
  CircularProgress,
  Grid,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import axios from "axios";
import { useHistory } from "react-router-dom";
import Footer from "../About/Footer";
import Form from "../Form";

const DOMAIN = process.env.REACT_APP_DOMAIN;

export default function PartnerWithUs() {
  const [fullname, setFullname] = useState("");
  const [org, setOrg] = useState("");
  const [designation, setDesignation] = useState("");
  const [contact, setContact] = useState("");
  const [mail, setMail] = useState("");
  const [partner, setPartner] = useState("");
  const [details, setDetails] = useState("");
  const [linkedIn, setLinkedIn] = useState("");
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [resp, setResp] = useState("");
  const history = useHistory();

  const handleFile = (event) => {
    setFile(event.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append("fullName", fullname);
    data.append("organization", org);
    data.append("designation", designation);
    data.append("contactNo", contact);
    data.append("mailId", mail);
    data.append("MarkoKnowPartner", partner);
    data.append("details", details);
    data.append("linkedinId", linkedIn);
    if (file) data.append("file", file);

    setLoading(true);
    try {
      await axios.post(`${DOMAIN}/api/postPartner`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
          authorization: window.localStorage.getItem("token"),
        },
      });
      setResp("Message Sent");
      history.push("/");
    } catch (err) {
      setResp("Internal Server Error!");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: { lg: "89.5vh", xs: "100%" },
          background: "#ffff",
          marginTop: { xs: 2, lg: 2 },
          marginBottom: { xs: 10, lg: 0 },
        }}
      >
        {/* <Form open={true} onClose={""} category={"Main Partner US"} type="" /> */}
        <Box
          sx={{
            display: "flex",
            width: { lg: "70%", xs: "90%" },
            borderRadius: "10px",
            flexDirection: { lg: "row", xs: "column-reverse" },
            gap: { lg: 0, xs: 2 },
            marginTop: { xs: 2, lg: 2 },
            background: "#fff",
          }}
        >
          <Box
            sx={{
              width: { xs: "100%", lg: "50%" },
              backgroundColor: "#fff",
              color: "white",
              padding: 4,
              borderTopLeftRadius: "10px",
              borderBottomLeftRadius: "10px",
              borderTopRightRadius: { xs: "10px", lg: 0 },
              borderBottomRightRadius: { xs: "10px", lg: 0 },
              boxShadow: 3,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "center",
                //   backgroundColor: "#8257E6",
                //   backgroundColor: 'rgb(143,130,212)',
                background:
                  "linear-gradient(164deg, rgba(143,130,212,1) 38%, rgba(106,88,198,1) 100%, rgba(255,255,255,1) 100%)",
                padding: 4,
                borderRadius: "10px",
                height: "85%",
              }}
            >
              {[
                {
                  title: "Partner with Us",
                  description: "Share your interest to partner with us",
                },
                {
                  title: "Attach Proposal",
                  description:
                    "Simply share any proposal you have or a regular PDF with contact",
                },
                {
                  title: "Get Started",
                  description:
                    "We will start processing your partnership request",
                },
              ].map((step, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    alignItems: "flex-start",
                    gap: 1,
                    mb: 3,
                    flexDirection: "column",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      bgcolor: "rgba(255, 255, 255, 0.2)",
                      p: 1,
                      borderRadius: "50%",
                    }}
                  >
                    <CheckIcon fontSize="small" />
                  </Box>
                  <Typography
                    variant="h6"
                    sx={{ fontSize: "1.125rem", fontWeight: 600 }}
                  >
                    {step.title}
                  </Typography>
                  <Typography variant="body2">{step.description}</Typography>
                </Box>
              ))}
            </Box>
          </Box>

          <Container
            component={Paper}
            elevation={3}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              p: 2,
              width: { xs: "100%", lg: "60%" },
            }}
          >
            {loading ? (
              <CircularProgress size={45} />
            ) : (
              <Box sx={{ width: "85%" }}>
                <Typography variant="h5" textAlign="center" fontWeight={600}>
                  Partner with Us
                </Typography>
                <Typography
                  variant="body2"
                  textAlign="center"
                  color="text.secondary"
                  mb={3}
                >
                  Fill out the form below to submit your partnership request
                </Typography>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label="Full Name"
                      variant="outlined"
                      value={fullname}
                      onChange={(e) => setFullname(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label="Organization"
                      variant="outlined"
                      value={org}
                      onChange={(e) => setOrg(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label="Designation"
                      variant="outlined"
                      value={designation}
                      onChange={(e) => setDesignation(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label="Contact Number"
                      variant="outlined"
                      value={contact}
                      onChange={(e) => setContact(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label="Email"
                      variant="outlined"
                      value={mail}
                      onChange={(e) => setMail(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Select
                      fullWidth
                      value={partner}
                      displayEmpty
                      onChange={(e) => setPartner(e.target.value)}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value="Markoknow Mentor">
                        Markoknow Mentor
                      </MenuItem>
                      <MenuItem value="Markoknow Student Partner">
                        Markoknow Student Partner
                      </MenuItem>
                      <MenuItem value="Markoknow Workshop Partner">
                        Markoknow Workshop Partner
                      </MenuItem>
                      <MenuItem value="Markoknow Service Partner">
                        Markoknow Service Partner
                      </MenuItem>
                      <MenuItem value="Markoknow Investor Partner">
                        Markoknow Investor Partner
                      </MenuItem>
                    </Select>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Details"
                      variant="outlined"
                      value={details}
                      onChange={(e) => setDetails(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="LinkedIn ID"
                      variant="outlined"
                      value={linkedIn}
                      onChange={(e) => setLinkedIn(e.target.value)}
                    />
                  </Grid>
                </Grid>
                <Button
                  fullWidth
                  variant="contained"
                  component="label"
                  sx={{
                    mt: 2,
                    bgcolor: "#8257E6",
                    ":hover": { bgcolor: "#6D48C4" },
                  }}
                >
                  Upload Proposal
                  <input type="file" hidden onChange={handleFile} />
                </Button>
                <Button
                  fullWidth
                  variant="contained"
                  sx={{
                    mt: 2,
                    bgcolor: "#8257E6",
                    ":hover": { bgcolor: "#6D48C4" },
                  }}
                  onClick={handleSubmit}
                >
                  Submit Request
                </Button>
              </Box>
            )}
          </Container>
        </Box>
      </Box>
      <Footer />
    </>
  );
}
